import React, { useState ,useEffect} from "react";
import SelexAnalysisNavbar from "./CommonC/SelexAnalysisNavbar";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { baseURL } from "../Config/config";
import Plot from "react-plotly.js";
import DownloadIcon from "@mui/icons-material/Download";
import {  useDispatch } from "react-redux";
import { renderMatches, useLocation } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import GlobalLoader from "./CommonC/GlobalLoader";
import LinearProgress from "@mui/material/LinearProgress";
import PropTypes from "prop-types";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { ZoomIn } from '@material-ui/icons';
import IconButton from "@mui/material/IconButton";
import { DialogContent } from "@mui/material";

import InfoIcon from "@mui/icons-material/Info";

import { AppBar, Tabs } from '@mui/material';
import { Checkbox } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import OutlinedInput from "@mui/material/OutlinedInput";
import SearchIcon from "@mui/icons-material/Search";
import { Radio, RadioGroup, FormControlLabel, FormLabel } from '@mui/material';
import Excel from "../images/excel.jpeg";
import selexmb from "../images/SelexMB Final.png";
import aimb from "../images/AIMB.png";


import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  Table,
} from "@mui/material";

import Projectcolors from "../Utils/Colors";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import SelexRiskNavbar from "./CommonC/SelexRiskNavbar";




const menuprops = {
  PaperProps: {
    style: {
      maxHeight: 150,
      width:50
    },
  },
};



function SelexRiskLandingPage(props) {
      const [loadednew, setLoadedNew] = React.useState(false);
      const [pid, setPid] = React.useState(false);
      const [pname, setPname] = React.useState(false);
        const [edit, setEdit] = React.useState(true);
      
    const [isLoading, setIsLoading] = React.useState(false);
  let loaded = false;
  let ProjectID = false;
  let projectName = false;
  let editAccess = true;
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    if (location.state.loaded) {
      setLoadedNew(location.state.loaded);
    }
  }, [location.state.loaded]);
  
  useEffect(() => {
    if (location.state.ProjectID) {
      ProjectID = location.state.ProjectID;
      setPid(ProjectID)
    }
  }, [location.state.ProjectID]);
  
  useEffect(() => {
    if (location.state.projectName) {
      projectName = location.state.projectName;
      setPname(projectName)
    }
  }, [location.state.projectName]);
  
  useEffect(() => {
    if (location.state.editAccess) {
      setEdit(location.state.editAccess);
    }
  }, [location.state.editAccess]);
  
  useEffect(() => {
    if (loadednew) {
      //getdiscretedata();
      //getstochasticdata();
      //getnewanalysisdata();
    }
  }, [loadednew]);

  return (
    <div>
      <div className="popup">
   

      <SelexRiskNavbar />
      </div>

     
      {isLoading ? <GlobalLoader /> : null}
    </div>
  );
}

export default SelexRiskLandingPage;
