import React, {  useState, useEffect } from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import csvfile from "../SampleFiles/CSV_TEMPLATE_MAIN.csv";
import tseriesfile from "../SampleFiles/Timeseries.csv";
import hybridtseries from "../SampleFiles/SelexMB_LSTM Template.csv";
import Projectcolors from "../../Utils/Colors";
import { baseURL } from "../../Config/config";
import Modal from "@mui/material/Modal";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import GlobalLoader from "../CommonC/GlobalLoader";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const useStyles = makeStyles({
  button: {
    textTransform: "none",
  },
});

function Importcsv(props) {
  const [selectedFile, setSelectedFile] = useState();
  const [updatedFile, setUpdatedFile] = useState();
  const [isSelected, setIsSelected] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [isChoosed, setIsChoosed] = useState(false);
  
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState("zero");
  const [defaultValue, setDefault] = React.useState(true);
  const [descrnull, setdescrnull] = React.useState(false);
  const [isfill, setisfill] = React.useState(false);
  const [descrcol, setdescrcol] = React.useState("");
  const [nofilefound, setnofilefound] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [descrDuplicate, setdescrduplicate] = React.useState(false);
  const [getLoader, setGetLoader] = React.useState(false);
  const [special, setSpecial] = React.useState(false);

  useEffect(() => {
    if (props.loaded) {
      getLoadCsv();
    }
    checkCsv();
  }, []);

  const getLoadCsv = () => {
    setIsLoading(true);
    localStorage.setItem("projectID", props.ProjectID);

    localStorage.setItem("projectName", props.projectName);
    fetch(`${baseURL}/getLoadCsv?projectID=${props.ProjectID}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())

      .then((data) => {
        if (data.message) {
          setIsUploaded(true);
          setIsSelected(true);
          setIsChoosed(true);
          setnofilefound(false);
          setIsLoading(false);
        } else {
          setIsUploaded(true);
          setnofilefound(true);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("caught it!", err);
      });
  };

  const checkCsv = () => {
    setIsLoading(true);
    const projectID = localStorage.getItem("projectID");

    fetch(`${baseURL}/getcheckcsv?projectID=${projectID}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())

      .then((data) => {
        if (data.message === 1) {
          setIsUploaded(true);
          setIsSelected(true);
          setIsChoosed(true);
          setnofilefound(false);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("caught it!", err);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    setDefault(false);
    if (event.target.value === "zero") {
      setDefault(true);
    }
  };

  function downloadCSV() {
    var hiddenElement = document.createElement("a");
    hiddenElement.href = csvfile;
    hiddenElement.target = "_blank";

    //provide the name for the CSV file to be downloaded
    hiddenElement.download = "CSV_TEMPLATE_MAIN.csv";
    hiddenElement.click();
  }

  function downloadTseries() {
    var hiddenElement = document.createElement("a");
    hiddenElement.href = tseriesfile;
    hiddenElement.target = "_blank";

    //provide the name for the CSV file to be downloaded
    hiddenElement.download = "CSV_TEMPLATE_MAIN_TSERIES.csv";
    hiddenElement.click();
  }

    function downloadHTseries() {
      var hiddenElement = document.createElement("a");
      hiddenElement.href = hybridtseries;
      hiddenElement.target = "_blank";
  
      //provide the name for the CSV file to be downloaded
      hiddenElement.download = "CSV_TEMPLATE_MAIN_HYBRID_TSERIES.csv";
      hiddenElement.click();
    }
  

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setUpdatedFile(event.target.files[0]);
    // setAllFiles(allFiles => [...allFiles,event.target.files[0],event.target.files[0]])
    setIsChoosed(true);
    setnofilefound(false);
    setIsSelected(false);
    setIsUploaded(false);
  };

  const handlespecialClose = () => {
    setSpecial(false);
  };

  const handleSubmission = () => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      const ProjectID = localStorage.getItem("projectID");
      const data = [selectedFile, updatedFile];
      data.map((item, i) => {
        formData.append(`File_${i}`, item);
      });
      formData.append("ProjectID", ProjectID);
      fetch(`${baseURL}/upload`, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((result) => {
          setIsLoading(false);
          if (result.message === 1) {
            setdescrnull(true);
            setIsSelected(false);
            setIsUploaded(false);
            setdescrcol(result.descrcol);
            setdescrduplicate(false);
          } else if (result.message === 2) {
            setdescrduplicate(true);
            setIsSelected(false);
            setIsUploaded(false);
            setOpen(false);
            setIsUploaded(false);
            setdescrnull(false);
          } else if (result.message === 3) {
            setdescrnull(false);
            setdescrduplicate(false);
            setIsSelected(false);
            setIsUploaded(false);
            setOpen(true);
          } else if (result.message === 4) {
            setdescrnull(false);
            setdescrduplicate(false);
            setIsSelected(false);
            setIsUploaded(false);
            setSpecial(true);
          } else {
            setdescrnull(false);
            setOpen(false);
            setdescrduplicate(false);
            setIsUploaded(true);
            setIsSelected(true);
            setIsChoosed(true);
            setnofilefound(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.error("Error:", error);
        });
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
    }
  };

  const NextStep = () => {
    if (isUploaded) {
      props.handleNextStep();
    }
  };

  const removenull = () => {
    setGetLoader(true);
    const ProjectID = localStorage.getItem("projectID");
    const body = {
      option: selectedValue,
      ProjectID,
    };

    try {
      fetch(`${baseURL}/removenull`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((result) => {
          setisfill(true);
          setdescrnull(false);
          setIsUploaded(true);
          setIsSelected(true);
          setIsChoosed(true);
          setGetLoader(false);
        })
        .catch((error) => {
          console.error("Error:", error);
          setGetLoader(false);
        });
    } catch (error) {
      console.log("error", error);
      setGetLoader(false);
    }
  };

  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={special}
        onClose={handlespecialClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ color: "red", fontSize: "13px" }}
        >
          {
            "Special Characters not accepted in CSV '@ _ ! # $ % ^ & * ( ) < > ? /  | { } ~ :' "
          }
        </DialogTitle>
      </Dialog>

      <Box
        m="auto"
        mt={0}
        sx={{
          width: 380,
          //height: 270,
          // margin:"0 470px",
          backgroundColor: Projectcolors.primary,
          padding: 5,
          boxShadow: 9,
          marginTop: "170px",
          border: 1,
          borderWidth: "4px",
          borderColor: "black",
          borderRadius: 3,
        }}
      >
        <input
          disabled={!props.editAccess}
          style={{ fontSize: "11px" }}
          type="file"
          name="file"
          accept=".csv"
          onChange={changeHandler}
        />

        {isLoading ? (
          <CircularProgress
            sx={{ color: "#fff", marginTop: 2, marginLeft: 2 }}
            size={25}
          />
        ) : (
          <Button
            onClick={handleSubmission}
            disabled={!isChoosed || !props.editAccess}
            mt={3}
            sx={{
              align: "center",
              width: "120px",
              height: "33px",
              marginTop: 3,
              backgroundColor: Projectcolors.white,
              fontStyle: "bold",
              color: "black",
              fontSize: "11px",
            }}
            variant="contained"
            size="large"
          >
            Upload csv
          </Button>
        )}
        {descrDuplicate ? (
          <div>
            <Alert
              sx={{
                marginTop: 2,
                fontSize: 13,
                color: "#000",
                backgroundColor: "#fff",
              }}
              variant="outlined"
              severity="error"
            >
              Duplicate values found in description row.
            </Alert>
          </div>
        ) : (
          <div></div>
        )}
        {isUploaded ? (
          <Alert
            sx={{
              marginTop: 2,
              fontSize: 10,
              color: "#fff",
              padding: "2px",
              width: "283px",
            }}
            variant="outlined"
            severity={nofilefound ? "error" : "success"}
          >
            {selectedFile ? (
              <div>{selectedFile.name} uploaded successfully</div>
            ) : (
              <div>
                {nofilefound ? (
                  <p>Please Upload File</p>
                ) : (
                  <p>File Loaded successfully</p>
                )}
              </div>
            )}
          </Alert>
        ) : (
          <div>
            {descrnull ? (
              <div>
                <Alert
                  sx={{
                    marginTop: 2,
                    fontSize: 13,
                    color: "#000",
                    backgroundColor: "#fff",
                  }}
                  variant="outlined"
                  severity="error"
                >
                  Description cannot be blank in column {descrcol}.Please
                  provide description and try uploading file again.
                </Alert>
              </div>
            ) : (
              <div>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <CloseIcon
                      onClick={handleClose}
                      sx={{ fontSize: "large", float: "right" }}
                    ></CloseIcon>

                    <Typography
                      id="modal-modal-title"
                      variant="h4"
                      component="h2"
                    >
                      Missing Data Warning!
                    </Typography>
                    <Typography
                      id="modal-modal-description"
                      variant="h5"
                      sx={{ mt: 2 }}
                    >
                      Fill the missing value with one of the options below:
                    </Typography>
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                        size="large"
                        sx={{ mt: 3 }}
                      >
                        <FormControlLabel
                          onChange={handleChange}
                          size="large"
                          value="zero"
                          control={
                            <Radio size="medium" checked={defaultValue} />
                          }
                          label={
                            <Typography variant="h5">
                              Zero (All missing values will be replace by 0)
                            </Typography>
                          }
                        />

                        <FormControlLabel
                          onChange={handleChange}
                          size="large"
                          value="mean"
                          control={<Radio size="medium" />}
                          label={
                            <Typography variant="h5">
                              Mean (All missing value will be replace by mean of
                              specific column)
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          onChange={handleChange}
                          size="large"
                          value="median"
                          control={<Radio size="medium" />}
                          label={
                            <Typography variant="h5">
                              Median (All missing value will be replace by
                              median of specific column)
                            </Typography>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                    <br />
                    {getLoader ? (
                      <GlobalLoader />
                    ) : (
                      <Button
                        onClick={removenull}
                        sx={{
                          marginLeft: 0,
                          marginTop: 3,
                          width: 120,
                          height: 40,
                          fontSize: 12,
                          padding: 2,
                          backgroundColor: Projectcolors.primary,
                          color: "white",
                          fontStyle: "bold",
                          marginBottom: 3,
                        }}
                        variant="contained"
                        textTransform="none"
                        className={classes.button}
                      >
                        Recondition
                      </Button>
                    )}

                    {isfill ? (
                      <div>
                        <Alert
                          sx={{ marginTop: 2, fontSize: 13 }}
                          variant="outlined"
                          severity="success"
                        >
                          Success! Filled Missing Values in Data.
                        </Alert>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </Box>
                </Modal>
              </div>
            )}
          </div>
        )}
        <Box style={{ float: "left", marginTop: 10 }}>
          <a
            onClick={() => downloadCSV()}
            style={{ color: "white", fontSize: "11px" }}
          >
            Download Template here
          </a>
        </Box>
        <br />
        <Box style={{ marginTop: 15 }}>
        <a
            onClick={() => downloadTseries()}
            style={{ color: "white", fontSize: "11px" }}
          >
            Download TSeries Template here
          </a>
        </Box>
        <Box style={{ marginTop: 6 }}>
        <a
            onClick={() => downloadHTseries()}
            style={{ color: "white", fontSize: "11px" }}
          >
            Download Hybrid Time Series Template here
          </a>
        </Box>
      
       
        <Button
          disabled={!isSelected}
          onClick={NextStep}
          // mt={5}
          sx={{
            // marginLeft: 37,
            // width: 10,
            minWidth: 7,
            // height: 33,
            fontSize: 13,
            // marginTop: 1,
            backgroundColor: Projectcolors.white,
            color: "black",
            fontStyle: "bold",
            margin: "29px 0 0 240px",
          }}
          variant="contained"
          // size="large"
        >
          <ChevronRightIcon sx={{ fontSize: "18px" }} />
        </Button>
      </Box>
    </div>
  );
}

export default Importcsv;
