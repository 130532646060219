import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import { Typography, Link } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import { baseURL } from "../Config/config";
import Projectcolors from "../Utils/Colors";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import TableViewIcon from "@mui/icons-material/TableView";
import ScatterPlotIcon from "@mui/icons-material/ScatterPlot";
import Plot from "react-plotly.js";
import Table from "@mui/material/Table";
import DownloadIcon from "@mui/icons-material/Download";
import GlobalLoader from "./CommonC/GlobalLoader";
import InputLabel from "@mui/material/InputLabel";
import "../Styles/draggable.css";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import LinearProgress from "@mui/material/LinearProgress";
import PropTypes from "prop-types";
import axios from "axios";
import Divider from "@mui/material/Divider";
import Excel from "../images/excel.jpeg";
import Hysys from "../images/hysys.jpeg";
import TextField from "@mui/material/TextField";



import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const useStyles = makeStyles({
  button: {
    textTransform: "none",
  },
});

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          sx={{ fontSize: 14 }}
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default function MultiCase(props) {
  const classes = useStyles();

  const initialState = new Array(50).fill(false);
  initialState[0] = true;
  const [checkedState, setCheckedState] = useState(initialState);
  const initialState2 = new Array(50).fill(false);
  const [index, setIndex] = React.useState();
  const [status, setStatus] = React.useState([]);
  const [values, setValues] = React.useState([]);
  const [minvalues, setminValues] = React.useState([]);
  const [independent, setIndependent] = React.useState([]);
  const [dependent, setDependent] = React.useState([]);
  const [unitsin, setunitsin] = React.useState([]);
  const [unitsde, setunitsde] = React.useState([]);
  const [minimum, setminimum] = React.useState([]);
  const [maximum, setmaximum] = React.useState([]);
  const [date, setDate] = React.useState("");
  const [finallist, setfinallist] = React.useState([]);
  const [xvalues, setxvalues] = React.useState([]);
  const [cat, setcat] = React.useState([]);
  const [modalTraining, setmodalTraining] = React.useState(false);
  const [getLoader, setGetLoader] = React.useState(false);
  const [logisticstate, setLogisticState] = React.useState(false);
  const [isCompleted, setisCompleted] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [inputopen, setinputOpen] = React.useState(false);
  const [errdownload, seterrdownload] = React.useState(false);
  const [choosed, setChoosed] = React.useState(0);
  const [timeseries, setTimeseries] = React.useState(0);
  const [forecast, setForecast] = React.useState(0);
  const [progress, setProgress] = React.useState(0);
  const [trained, setTrained] = React.useState(false);
  const [freq, setFreq] = React.useState("hi");
  const [selectedsnapshot, setselectedsnapshot] = React.useState(0);
  const [snapdetails, setsnapdetails] = React.useState([]);
  const [fp, setfp] = React.useState([])
  const [indefp, setindefp] = React.useState([])
  const [usefp, setusefp] = React.useState(false);
  const [fpmess, setfpmess] = React.useState(false);



  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleallClose = () => {
    setinputOpen(false);
  };

  const handleerrClickOpen = () => {
    seterrdownload(true);
  };

  const handleerrClose = () => {
    seterrdownload(false);
  };
  const handlefpMessage = () => {
    setfpmess(false);
    seterrdownload(false);
  };

  let newArray = [];
  let minArray = [];

  const initializestatus = (data) => {

    if (data.usefp == true){
      data.fp[1].map((item,i)=>{
        newArray.push(true)
      })
    }
    else{
    data.dependent.map((item, i) => {
      newArray.push(true);
    });
  }

    setStatus(newArray);
    if (data.minimum) {
      minArray.push(data.minimum[0]);
      minArray.push(data.maximum[0]);
      setminValues(minArray);
    }
  };

  const handleClick = (idx) => {
    setIndex(idx);
    let index = idx;
    const dataArray = Array.from(status);
    dataArray[idx] = !status[idx];
    setStatus(dataArray);
  };

  const handlenewchange =(event,idx) =>{
    console.log(idx)
    const dataArray = Array.from(indefp);
    dataArray[idx] = event.target.value;
    setindefp(dataArray);

  }

  React.useEffect(() => {
    getmulticaseData();
  }, []);

  const getmulticaseData = () => {
    setGetLoader(true);
    const projectID = localStorage.getItem("projectID");
    fetch(
      `${baseURL}/getmulticasedata?projectID=${projectID}&loaded=${props.loaded}`
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.message === 1) {
          setLogisticState(true);
          setisCompleted(data.isCompleted);
          setGetLoader(false);
        } else {
          console.log("We received the reponse");
          console.log("data", data);

          if (data.finallist) {
            if (data.timeseries) {
              console.log("Yes");
              console.log("data", data);
              setTimeseries(data.timeseries);
              setfinallist(data.finallist);
              setxvalues(data.xvalues);
              setFreq(data.frequency);
              setDependent(data.dependent);
              setcat(data.cat);
              setForecast(data.forecast);

              console.log(data);
            } else {
              setfinallist(data.finallist);
              setxvalues(data.xvalues);
              setminValues(data.minvalues);
              setValues(data.values);
              setcat(data.cat);
              setChoosed(data.state.indexOf(true));
              setCheckedState(data.state);
              setDependent(data.dependent);
              const projectID = localStorage.getItem("projectID");
              if(data.usefp == true){
              console.log(data.fp)
              setfp(data.fp)
              }
              else{
                setfp([])
              }
            }
          }



          setIndependent(data.independent);
          //setDependent(data.dependent);
          setunitsin(data.unitsin);
          setunitsde(data.unitsde);
          setisCompleted(data.isCompleted);
          setTimeseries(data.timeseries);
          setFreq(data.frequency);
          setselectedsnapshot(data.selectedsnapshot);
          setsnapdetails(data.snapdetails);
          setcat(data.cat);

          if (data.minimum) {
            setminimum(data.minimum);
            setmaximum(data.maximum);
          }
          if(data.usefp == true){
          console.log("Ye wala b call hora")
          setfp(data.fp)
            }
          

          initializestatus(data);
          setGetLoader(false);
        }
      })
      .catch((err) => {
        setGetLoader(false);
        console.log(err);
      });
  };

  let newmin = [];

  const snapshotchange = (e, i) => {
    let newArr = [];
    newArr = initialState2;
    newArr[i] = !newArr[i];
    setChoosed(i);

    setCheckedState(newArr);
    let minArray = [];
    if (minimum.length > 0) {
      minArray.push(minimum[i]);
      minArray.push(maximum[i]);
      setminValues(minArray);
    }
  };

  const handleChange = (event, idx) => {
    const dataArray = Array.from(values);
    dataArray[idx] = event.target.value;
    setValues(dataArray);
  };

  const handleForecastChange = (event) => {
    setForecast(event.target.value);
  };
  const handledateChange = (event) => {
    setDate(event.target.value);
  }

  const handleMinChange = (event, idx) => {
    const dataArray = Array.from(minvalues);
    dataArray[idx] = event.target.value;
    setminValues(dataArray);
  };
  function checkforecastinput() {
    console.log("Inside forecast input");
    var isnulll = false;
    if (forecast == 0) {
      setinputOpen(true);
      isnulll = true;
    }
    if (isnulll == false) {
      console.log("yes");
      forecastmulticase();
    }
  }

  let timer;
  let secondtimer;

  function checkinput() {
    if (independent.length > 1) {
      var isnulll = false;
      if (values.length == 0 || values.length < independent.length - 1) {
        setinputOpen(true);
        isnulll = true;
      }
      if (values.length == independent.length - 1) {
        for (var i = 0; i < values.length; i++) {
          if (values[i] == "" || values[i] == null) {
            setinputOpen(true);
            isnulll = true;
          }
        }
      }
      if (minvalues.length != 3) {
        setinputOpen(true);
        isnulll = true;
      }
      if (minvalues.length == 3) {
        for (var i = 0; i < minvalues.length; i++) {
          if (minvalues[i] == "" || minvalues[i] == null) {
            setinputOpen(true);
            isnulll = true;
          }
        }
      }
      if (isnulll == false) {
        predictmulticase();
      }
    } else {
      var isnulll = false;
      if (minvalues.length != 3) {
        setinputOpen(true);
        isnulll = true;
      }
      if (minvalues.length == 3) {
        for (var i = 0; i < minvalues.length; i++) {
          if (minvalues[i] == "" || minvalues[i] == null) {
            setinputOpen(true);
            isnulll = true;
          }
        }
      }
      if (isnulll == false) {
        predictmulticase();
      }
    }
  }

  const predictmulticase = () => {
    try {
      const projectID = localStorage.getItem("projectID");
      
       const body = JSON.stringify({
        values,
        indefp : [],
        projectID,
        time: new Date(),
        minvalues,
        checkedState,
      });
    
      setProgress(0);
      setTrained(false);

      setmodalTraining(true);
      const config = {
        onUploadProgress: (progressEvent) => {
          let seconds = 1500;
          timer = setInterval(() => {
            setProgress((prevProgress) =>
              prevProgress >= 99 ? 99 : prevProgress + 1
            );
          }, seconds);
        },
        headers: {
          "Content-Type": "application/json",
        },
      };

      axios
        .post(`${baseURL}/predictmulticase`, body, config)
        .then((response) => response)
        .then((result) => {
          const data = result.data;
          if (data.message == 1){
            setfpmess(true);
            setTrained(false);
            clearInterval(timer);
            setmodalTraining(false);
          }
          else{
          setfp(data.fp)
          setfinallist(data.finallist);          
          setxvalues(data.stepper);
          setDependent(data.dependent);
          setcat(data.cat);
          setmodalTraining(false);
          clearInterval(timer);
          setTrained(true);
          setProgress(100);
          }
        })
        .catch((error) => {
          setmodalTraining(false);
          console.error("Error:", error);
        });
    } catch (error) {
      setmodalTraining(false);
      console.log("error", error);
    }
  };

  const forecastmulticase = () => {
    try {
      const projectID = localStorage.getItem("projectID");
      const body = JSON.stringify({
        forecast,
        date,
        projectID,
        time: new Date(),
      });
      setProgress(0);
      setTrained(false);

      setmodalTraining(true);

      const config = {
        onUploadProgress: (progressEvent) => {
          let seconds = 1200;
          timer = setInterval(() => {
            setProgress((prevProgress) =>
              prevProgress >= 99 ? 99 : prevProgress + 1
            );
          }, seconds);
        },
        headers: {
          "Content-Type": "application/json",
        },
      };
      axios
        .post(`${baseURL}/forecast`, body, config)
        .then((response) => response)
        .then((result) => {
          const data = result.data;
          setfinallist(data.finallist);
          setxvalues(data.stepper);
          setDependent(data.dependent);
          setcat(data.cat);
          setmodalTraining(false);
          clearInterval(timer);
          setTrained(true);
          setProgress(100);
        })
        .catch((error) => {
          setmodalTraining(false);
          console.error("Error:", error);
        });
    } catch (error) {
      setmodalTraining(false);
      console.log("error", error);
    }
  };

  function downloadReport() {
    const projectID = localStorage.getItem("projectID");
    const projectName = localStorage.getItem("projectName");
    setGetLoader(true);

    fetch(`${baseURL}/getreportdata?projectID=${projectID}`)
      .then((res) => res.blob())
      .then((blob) => {
        var file = new Blob([blob], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        var url = URL.createObjectURL(file);
        var link = document.createElement("a");
        link.download = projectName + ".xlsx";
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        fetch(`${baseURL}/deletefile?projectName=${projectName}`, {
          method: "POST",
          body: JSON.stringify(projectName),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())
          .then((result) => {
            setGetLoader(false);
          })

          .catch((error) => {
            seterrdownload(true);
            setGetLoader(false);
          });
        setGetLoader(false);
      })
      .catch((err) => {
        seterrdownload(true);
        setGetLoader(false);
      });
  }
  function downloadHysysFolder(){
    const projectID = localStorage.getItem("projectID");
    const projectName = localStorage.getItem("projectName");
    setGetLoader(true);
    const user_token = localStorage.getItem("usertoken");
    fetch(`${baseURL}/gethysyszipfile?projectID=${projectID}&user_token=${user_token}`)
      .then((res) => res.blob())
      .then((blob) => {
        var file = new Blob([blob]);
        var url = URL.createObjectURL(file);
        var link = document.createElement("a");
        link.download = projectName +".zip";
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        fetch(`${baseURL}/deletehysysfile?projectName=${projectName}`, {
          method: "POST",
          body: JSON.stringify(projectName),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())
          .then((result) => {
            setGetLoader(false);
          })
          .catch((error) => {
            console.error("Error:", error);
            setGetLoader(false);
          });
        setGetLoader(false);
        /**
        var file = new Blob([blob], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        var url = URL.createObjectURL(file);
        var link = document.createElement("a");
        link.download = projectName + ".zip";
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setGetLoader(false);
         */

      })
      .catch((err) => {
        setGetLoader(false);
        seterrdownload(true);
        console.log("Error", err);
      });

  }
  function downloadCSV(name, y_test, y_pred, timeseries) {
    var arr = [];
    var original;
    var predicted;
    for (var i = 0; i < y_test.length; i++) {
      if (
        y_test[i].toString().startsWith("0.") ||
        y_test[i].toString().startsWith("-0.")
      ) {
        original = parseFloat(y_test[i]).toExponential(3);
      } else {
        if (timeseries === 0) {
          original = parseFloat(y_test[i]).toFixed(3);
        } else {
          original = y_test[i];
        }
      }
      if (
        y_pred[i].toString().startsWith("0.") ||
        y_pred[i].toString().startsWith("-0.")
      ) {
        predicted = parseFloat(y_pred[i]).toExponential(3);
      } else {
        predicted = parseFloat(y_pred[i]).toFixed(3);
      }

      arr.push([original, predicted]);
    }

    if (timeseries !== 1) {
      var csv = "Observed,Predicted\n";
    } else {
      var csv = "Timestamp,Forecasted\n";
    }

    arr.forEach(function (row) {
      csv += row.join(",");
      csv += "\n";
    });
    var hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
    hiddenElement.target = "_blank";

    //provide the name for the CSV file to be downloaded
    hiddenElement.download = name + ".csv";
    hiddenElement.click();
  }

  return (
    <div>
      <Dialog
        open={inputopen}
        onClose={handleallClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ color: "red", fontSize: "13px" }}
        >
          {"All Inputs require to predict new values"}
        </DialogTitle>
      </Dialog>
      <Dialog
        open={errdownload}
        onClose={handleerrClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ color: "red", fontSize: "13px" }}
        >
          {"Error Downloading !"}
        </DialogTitle>
      </Dialog>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ color: "red", fontSize: "13px" }}
        >
          {
            "Incomplete Project ! Project Report Cannot be Downloaded"
          }
        </DialogTitle>
      </Dialog>
      <Dialog
        open={fpmess}
        onClose={handlefpMessage}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ color: "red", fontSize: "13px" }}
        >
          {"Please Fix First Principles Calculations"}
        </DialogTitle>
        </Dialog>

      {logisticstate ? (
        <div style={{ marginTop: "5px", display: "inline-block" }}>
          <Box
            component={Paper}
            elevation={10}
            m="auto"
            sx={{
              height: 600,
              width: 1400,
              padding: 4,
            }}
          >
            <div style={{ margin: "auto", width: "100%", textAlign: "center" }}>
              <Typography sx={{ fontSize: 17, color: "red", marginTop: 2 }}>
                Make sure Step 2 (KPI) does not include categorical data to run
                the multicase analysis.
              </Typography>{" "}
            </div>
          </Box>
        </div>
      ) : (
        <Box sx={{ border: 0 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              border: 0,
              marginTop: 1,
            }}
          >
            <div style={{ marginTop: "5px", display: "inline-block" }}>
              <Box
                component={Paper}
                elevation={10}
                m="auto"
                sx={{
                  //height: 650,
                  width: "fit-content",
                  //backgroundColor:"blue",
                  padding: 1,
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "auto",
                  marginRight: "auto",
                  border: 0,
                }}
              >
                <div>
                  <Box
                    component={Paper}
                    elevation={3}
                    sx={{
                      height: 580,
                      width: 580,
                      padding: 1,
                      overflowX: "scroll",
                      overflowY: "scroll",
                      marginTop: 4,
                    }}
                  >
                    {timeseries ? (
                      <div>
                        <div>
                          {forecast > 0 ? (
                            <div>
                               <Typography sx={{ fontSize: 11,marginLeft: 3,
                                  marginTop:2 }}>
                                                                  Start Date
                                                                </Typography>
                                                                <TextField
                                                                  id="datetime-local"
                                                                  placeholder=""
                                                                  type="datetime-local"
                                                                  defaultValue={date}
                                                                  onChange={(e) => {
                                                                    handledateChange(e);
                                                                  }}
                                                                  sx={{ width: "170px" ,marginTop: 2,
                                                                    marginLeft: 3, }}
                                                                  InputLabelProps={{
                                                                    shrink: true,
                                                                  }}
                                                                  inputProps={{
                                                                    fontSize: "11px",
                                                                    height: "37px",
                                                                  }}
                                                                />
                              <Typography
                                sx={{
                                  width: 90,
                                  //display: "inline",
                                  fontSize: 12,
                                  marginLeft: 3,
                                  marginTop:2
                                }}
                              >
                                Forecast
                              </Typography>{" "}
                              <OutlinedInput
                                sx={{
                                  width: "135px",
                                  fontSize: "11px",
                                  height: "30px",
                                  marginTop: 2,

                                  marginLeft: 3,
                                }}
                                type="number"
                                id="outlined-adornment-amount"
                                onChange={(e) => {
                                  handleForecastChange(e);
                                }}
                                defaultValue={forecast}
                              />
                              <Typography
                                sx={{ display:"inline", marginLeft: 2 }}
                              >
                                {freq}
                              </Typography>
                            </div>
                          ) : (
                            <div>
                              <Typography sx={{ fontSize: 11,marginLeft: 3,
                                  marginTop:2 }}>
                                                                  Start Date
                                                                </Typography>
                                                                <TextField
                                                                  id="datetime-local"
                                                                  placeholder=""
                                                                  type="datetime-local"
                                                                  
                                                                  onChange={(e) => {
                                                                    handledateChange(e);
                                                                  }}
                                                                  sx={{ width: "170px",marginTop: 2,
                                                                    marginLeft: 3, }}
                                                                  InputLabelProps={{
                                                                    shrink: true,
                                                                  }}
                                                                  inputProps={{
                                                                    fontSize: "11px",
                                                                    height: "37px",
                                                                  }}
                                                                />
                                                          
                              <Typography
                                sx={{
                                  width: 90,
                                  //display: "inline",
                                  fontSize: 12,
                                  marginLeft: 3,
                                  marginTop:2
                                }}
                              >
                                Forecast
                              </Typography>

                              <OutlinedInput
                                sx={{
                                  width: "135px",
                                  fontSize: "11px",
                                  height: "30px",
                                  marginTop: 2,
                                  marginLeft: 3,
                                }}
                                id="outlined-adornment-amount"
                                type="number"
                                onChange={(e) => {
                                  handleForecastChange(e);
                                }}
                              />
                              <Typography
                                sx={{ display:"inline", marginLeft: 2 }}
                              >
                                {freq}
                              </Typography>
                            </div>
                          )}
                        </div>

                        <Box sx={{ height: "60px" }}>
                          <Button
                            editAccess={props.editAccess}
                            disabled={!props.editAccess}
                            onClick={() => checkforecastinput()}
                            mr={4}
                            sx={{
                              width: 140,
                              height: 35,
                              fontSize: "12px",
                              backgroundColor: Projectcolors.primary,
                              color: "#fff",
                              fontStyle: "bold",
                              marginBottom: 2,
                              float: "left",
                              marginRight: 5,
                              marginTop: 2,
                              marginLeft: 2,
                              textTransform: "none",
                            }}
                            variant="contained"
                            size="large"
                            textTransform="none"
                            className={classes.button}
                          >
                            Run SelexMB
                          </Button>
                        </Box>

                        {modalTraining ? (
                          <Box
                            sx={{
                              width: "100%",
                              float: "left",
                              marginBottom: 0,
                              height: "10px",
                              marginTop: "0px",
                            }}
                          >
                            <LinearProgressWithLabel
                              value={progress}
                              sx={{ height: "6px" }}
                            />
                          </Box>
                        ) : null}

                        {trained ? (
                          <Box
                            sx={{
                              width: "100%",
                              float: "left",
                              marginBottom: 0,
                              height: "10px",
                              marginTop: "0px",
                            }}
                          >
                            <LinearProgressWithLabel
                              value={100}
                              sx={{ height: "6px" }}
                            />
                          </Box>
                        ) : null}
                      </div>
                    ) : (
                      <div>
                    
                        {fp.length>0?
                      fp[0].map((item, i) => {
                        return (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <Box
                              sx={{
                                width: 260,
                                maxWidth: 240,
                                display: "flex",
                                flexDirection: "row",
                                padding: 1,
                              }}
                            >
                              <div>
                                <Radio
                                  key={i}
                                  checked={checkedState[i]}
                                  onChange={(e) => snapshotchange(e, i)}
                                  value={i}
                                  size="medium"
                                />
                              </div>
                              <div style={{ padding: "10.5px" }}>
                                <Typography
                                  sx={{
                                    width: 90,
                                    display: "inline",
                                    fontSize: 12,
                                  }}
                                >
                                  {item[1]}
                                </Typography>
                              </div>
                            </Box>
                            <div>
                              {checkedState[i] ? (
                                <div>
                                  {minimum.length > 0 &&
                                  maximum.length > 0 ? (
                                    <div>
                                      {" "}
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          marginLeft: "0px",
                                        }}
                                      >
                                        <div>
                                          {" "}
                                          <FormControl
                                            sx={{
                                              float: "right",
                                              marginTop: 2,
                                              marginLeft: 0,
                                            }}
                                          >
                                            <InputLabel
                                              sx={{
                                                fontSize: 11,
                                                letterSpacing: "0.0px",
                                                lineHeight: "9px",
                                                textAlign: "center",
                                              }}
                                            >
                                              MIN
                                            </InputLabel>
                                            <OutlinedInput
                                              sx={{
                                                width: 90,
                                                fontSize: 12,
                                                height: 35,
                                              }}
                                              id="outlined-adornment-amount"
                                              label="MIN"
                                              onChange={(e) => {
                                                handleMinChange(e, 0);
                                              }}
                                              defaultValue={minimum[i]}
                                            />
                                          </FormControl>
                                        </div>
                                        <div>
                                          {" "}
                                          <FormControl
                                            sx={{
                                              float: "right",
                                              marginTop: 2,
                                              marginLeft: 2,
                                            }}
                                          >
                                            <InputLabel
                                              sx={{
                                                fontSize: 11,
                                                letterSpacing: "0.0px",
                                                lineHeight: "9px",
                                                textAlign: "center",
                                              }}
                                            >
                                              MAX
                                            </InputLabel>
                                            <OutlinedInput
                                              sx={{
                                                width: 90,
                                                fontSize: 12,
                                                height: 35,
                                              }}
                                              id="outlined-adornment-amount"
                                              label="MAX"
                                              onChange={(e) => {
                                                handleMinChange(e, 1);
                                              }}
                                              defaultValue={maximum[i]}
                                            />
                                          </FormControl>
                                        </div>
                                        <div>
                                          <FormControl
                                            sx={{
                                              float: "right",
                                              marginTop: 2,
                                              marginLeft: 2,
                                            }}
                                          >
                                            <InputLabel
                                              sx={{
                                                fontSize: 11,
                                                letterSpacing: "0.0px",
                                                lineHeight: "9px",
                                                textAlign: "center",
                                              }}
                                            >
                                              STEP
                                            </InputLabel>
                                            <OutlinedInput
                                              sx={{
                                                width: 90,
                                                fontSize: 12,
                                                height: 35,
                                              }}
                                              id="outlined-adornment-amount"
                                              label="MIN"
                                              onChange={(e) => {
                                                handleMinChange(e, 2);
                                              }}
                                            />
                                          </FormControl>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div>
                                      {minvalues.length > 0 ? (
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginLeft: "0px",
                                          }}
                                        >
                                          <div>
                                            <FormControl
                                              sx={{
                                                float: "right",
                                                marginTop: 2,
                                                marginLeft: 0,
                                              }}
                                            >
                                              <InputLabel
                                                sx={{
                                                  fontSize: 11,
                                                  letterSpacing: "0.0px",
                                                  lineHeight: "9px",
                                                  textAlign: "center",
                                                }}
                                              >
                                                MIN
                                              </InputLabel>
                                              <OutlinedInput
                                                sx={{
                                                  width: 90,
                                                  fontSize: 12,
                                                  height: 35,
                                                }}
                                                id="outlined-adornment-amount"
                                                label="MIN"
                                                onChange={(e) => {
                                                  handleMinChange(e, 0);
                                                }}
                                                defaultValue={minvalues[0]}
                                              />
                                            </FormControl>
                                          </div>
                                          <div>
                                            <FormControl
                                              sx={{
                                                float: "right",
                                                marginTop: 2,
                                                marginLeft: 2,
                                              }}
                                            >
                                              <InputLabel
                                                sx={{
                                                  fontSize: 11,
                                                  letterSpacing: "0.0px",
                                                  lineHeight: "9px",
                                                  textAlign: "center",
                                                }}
                                              >
                                                MAX
                                              </InputLabel>
                                              <OutlinedInput
                                                sx={{
                                                  width: 90,
                                                  fontSize: 12,
                                                  height: 35,
                                                }}
                                                id="outlined-adornment-amount"
                                                label="MAX"
                                                onChange={(e) => {
                                                  handleMinChange(e, 1);
                                                }}
                                                defaultValue={minvalues[1]}
                                              />
                                            </FormControl>
                                          </div>
                                          <div>
                                            <FormControl
                                              sx={{
                                                float: "right",
                                                marginTop: 2,
                                                marginLeft: 2,
                                              }}
                                            >
                                              <InputLabel
                                                sx={{
                                                  fontSize: 11,
                                                  letterSpacing: "0.0px",
                                                  lineHeight: "9px",
                                                  textAlign: "center",
                                                }}
                                              >
                                                STEP
                                              </InputLabel>
                                              <OutlinedInput
                                                sx={{
                                                  width: 90,
                                                  fontSize: 12,
                                                  height: 35,
                                                }}
                                                id="outlined-adornment-amount"
                                                label="MIN"
                                                onChange={(e) => {
                                                  handleMinChange(e, 2);
                                                }}
                                                defaultValue={minvalues[2]}
                                              />
                                            </FormControl>
                                          </div>
                                        </div>
                                      ) : (
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginLeft: "0px",
                                          }}
                                        >
                                          <div>
                                            <FormControl
                                              sx={{
                                                float: "right",
                                                marginTop: 2,
                                                marginLeft: 0,
                                              }}
                                            >
                                              <InputLabel
                                                sx={{
                                                  fontSize: 11,
                                                  letterSpacing: "0.0px",
                                                  lineHeight: "9px",
                                                  textAlign: "center",
                                                }}
                                              >
                                                MIN
                                              </InputLabel>
                                              <OutlinedInput
                                                sx={{
                                                  width: 90,
                                                  fontSize: 12,
                                                  height: 35,
                                                }}
                                                id="outlined-adornment-amount"
                                                label="MIN"
                                                onChange={(e) => {
                                                  handleMinChange(e, 0);
                                                }}
                                              />
                                            </FormControl>
                                          </div>
                                          <div>
                                            <FormControl
                                              sx={{
                                                float: "right",
                                                marginTop: 2,
                                                marginLeft: 2,
                                              }}
                                            >
                                              <InputLabel
                                                sx={{
                                                  fontSize: 11,
                                                  letterSpacing: "0.0px",
                                                  lineHeight: "9px",
                                                  textAlign: "center",
                                                }}
                                              >
                                                MAX
                                              </InputLabel>
                                              <OutlinedInput
                                                sx={{
                                                  width: 90,
                                                  fontSize: 12,
                                                  height: 35,
                                                }}
                                                id="outlined-adornment-amount"
                                                label="MAX"
                                                onChange={(e) => {
                                                  handleMinChange(e, 1);
                                                }}
                                              />
                                            </FormControl>
                                          </div>
                                          <div>
                                            <FormControl
                                              sx={{
                                                float: "right",
                                                marginTop: 2,
                                                marginLeft: 2,
                                              }}
                                            >
                                              <InputLabel
                                                sx={{
                                                  fontSize: 11,
                                                  letterSpacing: "0.0px",
                                                  lineHeight: "9px",
                                                  textAlign: "center",
                                                }}
                                              >
                                                STEP
                                              </InputLabel>
                                              <OutlinedInput
                                                sx={{
                                                  width: 90,
                                                  fontSize: 12,
                                                  height: 35,
                                                }}
                                                id="outlined-adornment-amount"
                                                label="STEP"
                                                onChange={(e) => {
                                                  handleMinChange(e, 2);
                                                }}
                                              />
                                            </FormControl>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <div>
                                  {values.length > 0 ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        marginLeft: "0px",
                                      }}
                                    >
                                      <div>
                                        <FormControl
                                          sx={{
                                            float: "right",
                                            marginTop: 2,
                                            marginLeft: 0,
                                          }}
                                        >
                                          <InputLabel
                                            sx={{
                                              fontSize: 11,
                                              letterSpacing: "0.0px",
                                              lineHeight: "9px",
                                              textAlign: "center",
                                            }}
                                          >
                                            Value
                                          </InputLabel>
                                          <OutlinedInput
                                            sx={{
                                              width: 90,
                                              fontSize: 12,
                                              height: 35,
                                            }}
                                            id="outlined-adornment-amount"
                                            label="MIN"
                                            onChange={(e) => {
                                              checkedState[
                                                independent.length - 1
                                              ]
                                                ? handleChange(e, i)
                                                : i > choosed
                                                ? handleChange(e, i - 1)
                                                : handleChange(e, i);
                                            }}
                                            defaultValue={
                                              checkedState[
                                                independent.length - 1
                                              ]
                                                ? values[i]
                                                : i > choosed
                                                ? values[i - 1]
                                                : values[i]
                                            }
                                          />
                                        </FormControl>
                                      </div>
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        marginLeft: "0px",
                                      }}
                                    >
                                      <div>
                                        <FormControl
                                          sx={{
                                            float: "right",
                                            marginTop: 2,
                                            marginLeft: 0,
                                          }}
                                        >
                                          <InputLabel
                                            sx={{
                                              fontSize: 11,
                                              letterSpacing: "0.0px",
                                              lineHeight: "9px",
                                              textAlign: "center",
                                            }}
                                          >
                                            Value
                                          </InputLabel>
                                          <OutlinedInput
                                            sx={{
                                              width: 90,
                                              fontSize: 12,
                                              height: 35,
                                            }}
                                            id="outlined-adornment-amount"
                                            label="MIN"
                                            onChange={(e) => {
                                              checkedState[
                                                independent.length - 1
                                              ]
                                                ? handleChange(e, i)
                                                : i > choosed
                                                ? handleChange(e, i - 1)
                                                : handleChange(e, i);
                                            }}
                                          />
                                        </FormControl>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </Box>
                        );
                      }):
                      independent.map((item, i) => {
                        return (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <Box
                              sx={{
                                width: 260,
                                maxWidth: 240,
                                display: "flex",
                                flexDirection: "row",
                                padding: 1,
                              }}
                            >
                              <div>
                                <Radio
                                  key={i}
                                  checked={checkedState[i]}
                                  onChange={(e) => snapshotchange(e, i)}
                                  value={i}
                                  size="medium"
                                />
                              </div>
                              <div style={{ padding: "10.5px" }}>
                                <Typography
                                  sx={{
                                    width: 90,
                                    display: "inline",
                                    fontSize: 12,
                                  }}
                                >
                                  {item}({unitsin[i]})
                                </Typography>
                              </div>
                            </Box>
                            <div>
                              {checkedState[i] ? (
                                <div>
                                  {minimum.length > 0 &&
                                  maximum.length > 0 ? (
                                    <div>
                                      {" "}
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          marginLeft: "0px",
                                        }}
                                      >
                                        <div>
                                          {" "}
                                          <FormControl
                                            sx={{
                                              float: "right",
                                              marginTop: 2,
                                              marginLeft: 0,
                                            }}
                                          >
                                            <InputLabel
                                              sx={{
                                                fontSize: 11,
                                                letterSpacing: "0.0px",
                                                lineHeight: "9px",
                                                textAlign: "center",
                                              }}
                                            >
                                              MIN
                                            </InputLabel>
                                            <OutlinedInput
                                              sx={{
                                                width: 90,
                                                fontSize: 12,
                                                height: 35,
                                              }}
                                              id="outlined-adornment-amount"
                                              label="MIN"
                                              onChange={(e) => {
                                                handleMinChange(e, 0);
                                              }}
                                              defaultValue={minimum[i]}
                                            />
                                          </FormControl>
                                        </div>
                                        <div>
                                          {" "}
                                          <FormControl
                                            sx={{
                                              float: "right",
                                              marginTop: 2,
                                              marginLeft: 2,
                                            }}
                                          >
                                            <InputLabel
                                              sx={{
                                                fontSize: 11,
                                                letterSpacing: "0.0px",
                                                lineHeight: "9px",
                                                textAlign: "center",
                                              }}
                                            >
                                              MAX
                                            </InputLabel>
                                            <OutlinedInput
                                              sx={{
                                                width: 90,
                                                fontSize: 12,
                                                height: 35,
                                              }}
                                              id="outlined-adornment-amount"
                                              label="MAX"
                                              onChange={(e) => {
                                                handleMinChange(e, 1);
                                              }}
                                              defaultValue={maximum[i]}
                                            />
                                          </FormControl>
                                        </div>
                                        <div>
                                          <FormControl
                                            sx={{
                                              float: "right",
                                              marginTop: 2,
                                              marginLeft: 2,
                                            }}
                                          >
                                            <InputLabel
                                              sx={{
                                                fontSize: 11,
                                                letterSpacing: "0.0px",
                                                lineHeight: "9px",
                                                textAlign: "center",
                                              }}
                                            >
                                              STEP
                                            </InputLabel>
                                            <OutlinedInput
                                              sx={{
                                                width: 90,
                                                fontSize: 12,
                                                height: 35,
                                              }}
                                              id="outlined-adornment-amount"
                                              label="MIN"
                                              onChange={(e) => {
                                                handleMinChange(e, 2);
                                              }}
                                            />
                                          </FormControl>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div>
                                      {minvalues.length > 0 ? (
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginLeft: "0px",
                                          }}
                                        >
                                          <div>
                                            <FormControl
                                              sx={{
                                                float: "right",
                                                marginTop: 2,
                                                marginLeft: 0,
                                              }}
                                            >
                                              <InputLabel
                                                sx={{
                                                  fontSize: 11,
                                                  letterSpacing: "0.0px",
                                                  lineHeight: "9px",
                                                  textAlign: "center",
                                                }}
                                              >
                                                MIN
                                              </InputLabel>
                                              <OutlinedInput
                                                sx={{
                                                  width: 90,
                                                  fontSize: 12,
                                                  height: 35,
                                                }}
                                                id="outlined-adornment-amount"
                                                label="MIN"
                                                onChange={(e) => {
                                                  handleMinChange(e, 0);
                                                }}
                                                defaultValue={minvalues[0]}
                                              />
                                            </FormControl>
                                          </div>
                                          <div>
                                            <FormControl
                                              sx={{
                                                float: "right",
                                                marginTop: 2,
                                                marginLeft: 2,
                                              }}
                                            >
                                              <InputLabel
                                                sx={{
                                                  fontSize: 11,
                                                  letterSpacing: "0.0px",
                                                  lineHeight: "9px",
                                                  textAlign: "center",
                                                }}
                                              >
                                                MAX
                                              </InputLabel>
                                              <OutlinedInput
                                                sx={{
                                                  width: 90,
                                                  fontSize: 12,
                                                  height: 35,
                                                }}
                                                id="outlined-adornment-amount"
                                                label="MAX"
                                                onChange={(e) => {
                                                  handleMinChange(e, 1);
                                                }}
                                                defaultValue={minvalues[1]}
                                              />
                                            </FormControl>
                                          </div>
                                          <div>
                                            <FormControl
                                              sx={{
                                                float: "right",
                                                marginTop: 2,
                                                marginLeft: 2,
                                              }}
                                            >
                                              <InputLabel
                                                sx={{
                                                  fontSize: 11,
                                                  letterSpacing: "0.0px",
                                                  lineHeight: "9px",
                                                  textAlign: "center",
                                                }}
                                              >
                                                STEP
                                              </InputLabel>
                                              <OutlinedInput
                                                sx={{
                                                  width: 90,
                                                  fontSize: 12,
                                                  height: 35,
                                                }}
                                                id="outlined-adornment-amount"
                                                label="MIN"
                                                onChange={(e) => {
                                                  handleMinChange(e, 2);
                                                }}
                                                defaultValue={minvalues[2]}
                                              />
                                            </FormControl>
                                          </div>
                                        </div>
                                      ) : (
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginLeft: "0px",
                                          }}
                                        >
                                          <div>
                                            <FormControl
                                              sx={{
                                                float: "right",
                                                marginTop: 2,
                                                marginLeft: 0,
                                              }}
                                            >
                                              <InputLabel
                                                sx={{
                                                  fontSize: 11,
                                                  letterSpacing: "0.0px",
                                                  lineHeight: "9px",
                                                  textAlign: "center",
                                                }}
                                              >
                                                MIN
                                              </InputLabel>
                                              <OutlinedInput
                                                sx={{
                                                  width: 90,
                                                  fontSize: 12,
                                                  height: 35,
                                                }}
                                                id="outlined-adornment-amount"
                                                label="MIN"
                                                onChange={(e) => {
                                                  handleMinChange(e, 0);
                                                }}
                                              />
                                            </FormControl>
                                          </div>
                                          <div>
                                            <FormControl
                                              sx={{
                                                float: "right",
                                                marginTop: 2,
                                                marginLeft: 2,
                                              }}
                                            >
                                              <InputLabel
                                                sx={{
                                                  fontSize: 11,
                                                  letterSpacing: "0.0px",
                                                  lineHeight: "9px",
                                                  textAlign: "center",
                                                }}
                                              >
                                                MAX
                                              </InputLabel>
                                              <OutlinedInput
                                                sx={{
                                                  width: 90,
                                                  fontSize: 12,
                                                  height: 35,
                                                }}
                                                id="outlined-adornment-amount"
                                                label="MAX"
                                                onChange={(e) => {
                                                  handleMinChange(e, 1);
                                                }}
                                              />
                                            </FormControl>
                                          </div>
                                          <div>
                                            <FormControl
                                              sx={{
                                                float: "right",
                                                marginTop: 2,
                                                marginLeft: 2,
                                              }}
                                            >
                                              <InputLabel
                                                sx={{
                                                  fontSize: 11,
                                                  letterSpacing: "0.0px",
                                                  lineHeight: "9px",
                                                  textAlign: "center",
                                                }}
                                              >
                                                STEP
                                              </InputLabel>
                                              <OutlinedInput
                                                sx={{
                                                  width: 90,
                                                  fontSize: 12,
                                                  height: 35,
                                                }}
                                                id="outlined-adornment-amount"
                                                label="STEP"
                                                onChange={(e) => {
                                                  handleMinChange(e, 2);
                                                }}
                                              />
                                            </FormControl>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <div>
                                  {values.length > 0 ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        marginLeft: "0px",
                                      }}
                                    >
                                      <div>
                                        <FormControl
                                          sx={{
                                            float: "right",
                                            marginTop: 2,
                                            marginLeft: 0,
                                          }}
                                        >
                                          <InputLabel
                                            sx={{
                                              fontSize: 11,
                                              letterSpacing: "0.0px",
                                              lineHeight: "9px",
                                              textAlign: "center",
                                            }}
                                          >
                                            Value
                                          </InputLabel>
                                          <OutlinedInput
                                            sx={{
                                              width: 90,
                                              fontSize: 12,
                                              height: 35,
                                            }}
                                            id="outlined-adornment-amount"
                                            label="MIN"
                                            onChange={(e) => {
                                              checkedState[
                                                independent.length - 1
                                              ]
                                                ? handleChange(e, i)
                                                : i > choosed
                                                ? handleChange(e, i - 1)
                                                : handleChange(e, i);
                                            }}
                                            defaultValue={
                                              checkedState[
                                                independent.length - 1
                                              ]
                                                ? values[i]
                                                : i > choosed
                                                ? values[i - 1]
                                                : values[i]
                                            }
                                          />
                                        </FormControl>
                                      </div>
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        marginLeft: "0px",
                                      }}
                                    >
                                      <div>
                                        <FormControl
                                          sx={{
                                            float: "right",
                                            marginTop: 2,
                                            marginLeft: 0,
                                          }}
                                        >
                                          <InputLabel
                                            sx={{
                                              fontSize: 11,
                                              letterSpacing: "0.0px",
                                              lineHeight: "9px",
                                              textAlign: "center",
                                            }}
                                          >
                                            Value
                                          </InputLabel>
                                          <OutlinedInput
                                            sx={{
                                              width: 90,
                                              fontSize: 12,
                                              height: 35,
                                            }}
                                            id="outlined-adornment-amount"
                                            label="MIN"
                                            onChange={(e) => {
                                              checkedState[
                                                independent.length - 1
                                              ]
                                                ? handleChange(e, i)
                                                : i > choosed
                                                ? handleChange(e, i - 1)
                                                : handleChange(e, i);
                                            }}
                                          />
                                        </FormControl>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </Box>
                        );
                      })
                    }
                        <Box sx={{ height: "60px" }}>
                          <Button
                            editAccess={props.editAccess}
                            disabled={!props.editAccess}
                            onClick={() => checkinput()}
                            mr={4}
                            sx={{
                              width: 140,
                              height: 35,
                              fontSize: "12px",
                              backgroundColor: Projectcolors.primary,
                              color: "#fff",
                              fontStyle: "bold",
                              marginBottom: 2,
                              float: "left",
                              marginRight: 5,
                              marginTop: 2,
                              marginLeft: 2,
                              textTransform: "none",
                            }}
                            variant="contained"
                            size="large"
                            textTransform="none"
                            className={classes.button}
                          >
                            Run SelexMB
                          </Button>
                          <br />
                          {modalTraining ? (
                            <Box
                              sx={{
                                width: "100%",
                                float: "left",
                                marginBottom: 0,
                                height: "10px",
                                marginTop: "0px",
                              }}
                            >
                              <LinearProgressWithLabel
                                value={progress}
                                sx={{ height: "6px" }}
                              />
                            </Box>
                          ) : null}

                          {trained ? (
                            <Box
                              sx={{
                                width: "100%",
                                float: "left",
                                marginBottom: 0,
                                height: "10px",
                                marginTop: "0px",
                              }}
                            >
                              <LinearProgressWithLabel
                                value={100}
                                sx={{ height: "6px" }}
                              />
                            </Box>
                          ) : null}
                        </Box>
                      </div>
                    )}
                  </Box>
                </div>

                <div>
                  {isCompleted ? (
                    <div
                      onClick={() => downloadReport()}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        float: "right",
                        ml: 0,
                        cursor: "pointer",
                      }}
                    >
                       <img
                                      alt ="arrow"
                                      src={Excel}
                                      style={{
                                        marginTop: 0,
                                        marginBottom: 1,
                                        marginRight: 1,
                                        width:30,
                                        height:30
                                      }}
                                    />
                      
                      <Link
                        sx={{
                          fontSize: "13px",
                          fontStyle: "bold",
                          float: "right",
                          ml: 0,
                          mr: 0.5,
                          // marginTop: 0,
                          // marginBottom: 1,
                          textTransform: "none",
                        }}
                        variant="contained"
                        size="large"
                        textTransform="none"
                      >
                         <span style={{marginLeft:3}}>Download Project Report</span>
                      </Link>
                    </div>
                  ) : (
                    <div
                      onClick={() => handleClickOpen()}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        float: "right",
                        ml: 0,
                        cursor: "pointer",
                      }}
                    >
                      <img
                                      alt ="arrow"
                                      src={Excel}
                                      style={{
                                        marginTop: 0,
                                        marginBottom: 1,
                                        marginRight: 1,
                                        width:30,
                                        height:30
                                      }}
                                    />
                      <Link
                        sx={{
                          fontSize: "13px",
                          fontStyle: "bold",
                          float: "right",
                          ml: 0,
                          mr: 0.5,
                          // marginTop: 0,
                          // marginBottom: 1,
                          textTransform: "none",
                        }}
                        variant="contained"
                        size="large"
                        textTransform="none"
                      >
                         <span style={{marginLeft:3}}>Download Project Report</span>
                      </Link>
                    </div>
                  )}

{isCompleted ? (
                 <div>
                 {timeseries?(null):(
                <div
                  onClick={() => downloadHysysFolder()}
                  style={{
                    //display: "flex",
                    //flexDirection: "row",
                    //alignItems: "center",
                    float: "right",
                    ml: 100,
                    cursor: "pointer",
                    marginRight:10,
                    marginTop:5
                  }}
                >
                   <img
                                      alt ="arrow"
                                      src={Hysys}
                                      style={{
                                        
                                        marginBottom: 1,
                                        marginRight: 7,
                                        width:20,
                                        height:20
                                      }}
                                      />
                  <Link
                    sx={{
                      fontSize: "13px",
                      fontStyle: "bold",
                      float: "right",
                      ml: 0,
                      mr: 0.5,
                      // marginTop: 0,
                      // marginBottom: 1,
                      textTransform: "none",
                    }}
                    variant="contained"
                    size="large"
                    textTransform="none"
                  >
                    
                    
                    <span style={{marginLeft:0,marginRight:3}}>Download HYSYS Extension</span>
                   
                  </Link>
                </div>
                 )}
                 </div>
                
              ) : (
                <div>
                {timeseries? (null):(
                  <div
                  onClick={() => handleClickOpen()}
                  style={{
                    //display: "flex",
                    //flexDirection: "row",
                    //alignItems: "center",
                    float: "right",
                    ml: 100,
                    cursor: "pointer",
                    marginRight:10,
                    marginTop:5
                  }}
                >
                  <img
                                      alt ="arrow"
                                      src={Hysys}
                                      style={{
                                        
                                        marginBottom: 1,
                                        marginRight: 7,
                                        width:20,
                                        height:20
                                      }}
                                      />
                  <Link
                    sx={{
                      fontSize: "13px",
                      fontStyle: "bold",
                      float: "right",
                      ml: 0,
                      mr: 0.5,
                      // marginTop: 0,
                      // marginBottom: 1,
                      textTransform: "none",
                    }}
                    variant="contained"
                    size="large"
                    textTransform="none"
                  >
                    
                    <span style={{marginLeft:0,marginRight:3}}>Download HYSYS Extension</span>
                  </Link>
                </div>
                )}
                </div>
               
              )}
                  <Box
                    component={Paper}
                    elevation={3}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      p: 0,
                      bgcolor: "background.paper",
                      flexWrap: "wrap",
                      width: 750,
                      height: 580,
                      overflowX: "scroll",
                      overflowY: "scroll",
                      marginLeft: 3,
                    }}
                    className="resizeable"
                  >
                    
                    {fp.length>0 && finallist.length > 0?
                    fp[1].map((item, i) => {
                        return (
                          <Box
                            component={Paper}
                            elevation={5}
                            sx={{
                              width: "300px",
                              height: "260px",
                              border: 1,
                              borderColor: Projectcolors.primary,
                              marginLeft: 3,
                              marginTop: 1,
                            }}
                            className="resizeable"
                          >
                            <div
                              style={{
                                backgroundColor: Projectcolors.primary,
                                height: 28,
                                padding: 4,
                              }}
                            >
                              <Typography
                                sx={{
                                  display: "inline",
                                  flex: 1,
                                  fontSize: 13,
                                  padding: 2,
                                  marginTop: 3,
                                  color: "white",
                                }}
                              >
                                {item[1].length > 20
                                  ? item[1].substring(0, 20) + "..."
                                  : item[1]}
                              </Typography>
                              <TableViewIcon
                                sx={{
                                  display: "inline",
                                  float: "right",
                                  fontSize: 20,
                                  marginRight: 3,
                                  color: "white",
                                }}
                                onClick={() => handleClick(i)}
                              />
                              <ScatterPlotIcon
                                sx={{
                                  display: "inline",
                                  float: "right",
                                  fontSize: 20,
                                  marginRight: 3,
                                  color: "white",
                                }}
                                onClick={() => handleClick(i)}
                              />
                            </div>

                            <div>
                              {status[i] ? (
                                <div>
                                  {timeseries ? (
                                    <Plot
                                      data={[
                                        {
                                          x: xvalues[i],
                                          y: finallist[i],
                                          type: "scatter",
                                          mode: "markers",
                                          marker: {
                                            color: Projectcolors.primary,
                                          },
                                        },
                                      ]}
                                      layout={{
                                        margin: { b: 50, l: 80, r: 10, t: 20 },
                                        width: 260,
                                        height: 200,
                                        title: {
                                          font: {
                                            size: 24,
                                          },
                                          xref: "paper",
                                          x: 0.05,
                                        },
                                        xaxis: {
                                          title: {
                                            text: "Dates",
                                            font: {
                                              size: 12,
                                              color: "#000",
                                            },
                                          },
                                          linewidth: 1,
                                          ticklen: 1,
                                          automargin: true,
                                        },
                                        yaxis: {
                                          title: {
                                            text: "Forecasted",
                                            font: {
                                              size: 12,
                                              color: "#000",
                                            },
                                          },
                                          linewidth: 1,
                                          ticklen: 5,
                                        },
                                      }}
                                      config={{ displayModeBar: false }}
                                    />
                                  ) : (
                                    <Plot
                                      data={[
                                        {
                                          x: xvalues,
                                          y: finallist[i],
                                          type: "scatter",
                                          mode: "markers",
                                          marker: {
                                            color: Projectcolors.primary,
                                          },
                                        },
                                      ]}
                                      layout={{
                                        margin: { b: 50, l: 100, r: 10, t: 20 },
                                        width: 260,
                                        height: 200,
                                        title: {
                                          font: {
                                            size: 24,
                                          },
                                          xref: "paper",
                                          x: 0.05,
                                        },
                                        xaxis: {
                                          title: {
                                            text: "Input",
                                            font: {
                                              size: 12,
                                              color: "#000",
                                            },
                                          },
                                          linewidth: 1,
                                          ticklen: 10,
                                        },
                                        yaxis: {
                                          title: {
                                            text: "Predicted",
                                            font: {
                                              size: 12,
                                              color: "#000",
                                            },
                                          },
                                          linewidth: 1,
                                          ticklen: 5,
                                        },
                                      }}
                                      config={{ displayModeBar: false }}
                                    />
                                  )}
                                </div>
                              ) : (
                                <TableContainer
                                  component={Paper}
                                  elevation={2}
                                  style={{
                                    width: 240,
                                    height: 200,
                                    padding: 0,
                                    display: "block",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    marginTop: 12,
                                    border: 1,
                                    borderWidth: "2px",
                                    borderColor: "black",
                                  }}
                                >
                                  <Table
                                    aria-label="sticky table"
                                    stickyHeader
                                    sx={{ color: "black" }}
                                  >
                                    <TableHead
                                      sx={{
                                        backgroundColor: Projectcolors.primary,
                                        height: 10,
                                      }}
                                    >
                                      <TableRow>
                                        <TableCell
                                          sx={{
                                            padding: "7px",
                                            fontSize: 11,
                                            color: "white",
                                            fontWeight: "bold",
                                            backgroundColor:
                                              Projectcolors.primary,
                                          }}
                                        >
                                          Input
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "7px",
                                            fontSize: 11,
                                            color: "white",
                                            fontWeight: "bold",
                                            backgroundColor:
                                              Projectcolors.primary,
                                          }}
                                        >
                                          Predicted{" "}
                                        </TableCell>

                                        <TableCell
                                          sx={{
                                            // padding: "7px",
                                            width: 5,
                                            color: "white",
                                            fontWeight: "bold",
                                            backgroundColor:
                                              Projectcolors.primary,
                                          }}
                                        >
                                          <DownloadIcon
                                            sx={{
                                              fontSize: "small",
                                              marginTop: 1,
                                              marginRight: 2,
                                            }}
                                            onClick={() => {
                                              timeseries
                                                ? downloadCSV(
                                                    item,
                                                    xvalues[i],
                                                    finallist[i],
                                                    timeseries
                                                  )
                                                : downloadCSV(
                                                    item,
                                                    xvalues,
                                                    finallist[i],
                                                    timeseries
                                                  );
                                            }}
                                          />
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    {timeseries ? (
                                      <TableBody>
                                        {xvalues[i].map((x, j) => {
                                          return (
                                            <TableRow>
                                              <TableCell
                                                sx={{
                                                  fontSize: 11,
                                                  padding: "7px",
                                                }}
                                              >
                                                {x}
                                              </TableCell>

                                              <TableCell
                                                sx={{
                                                  fontSize: 11,
                                                  padding: "7px",
                                                }}
                                              >
                                                {finallist[i][j]
                                                  .toString()
                                                  .startsWith("0.") ||
                                                finallist[i][j]
                                                  .toString()
                                                  .startsWith("-0.") ? (
                                                  <div>
                                                    {finallist[i][
                                                      j
                                                    ].toExponential(3)}
                                                  </div>
                                                ) : (
                                                  <div>
                                                    {finallist[i][j].toFixed(3)}
                                                  </div>
                                                )}
                                              </TableCell>
                                              <TableCell></TableCell>
                                            </TableRow>
                                          );
                                        })}
                                      </TableBody>
                                    ) : (
                                      <TableBody>
                                        {xvalues.map((x, j) => {
                                          return (
                                            <TableRow>
                                              <TableCell
                                                sx={{
                                                  fontSize: 11,
                                                  padding: "7px",
                                                }}
                                              >
                                                {x
                                                  .toString()
                                                  .startsWith("0.") ||
                                                x
                                                  .toString()
                                                  .startsWith("-0.") ? (
                                                  <div>
                                                    {x.toExponential(3)}
                                                  </div>
                                                ) : (
                                                  <div>{x.toFixed(3)}</div>
                                                )}
                                              </TableCell>

                                              <TableCell
                                                sx={{
                                                  fontSize: 11,
                                                  padding: "7px",
                                                }}
                                              >
                                                {finallist[i][j]}
                                              </TableCell>
                                              <TableCell></TableCell>
                                            </TableRow>
                                          );
                                        })}
                                      </TableBody>
                                    )}
                                  </Table>
                                </TableContainer>
                              )}
                            </div>
                          </Box>
                        );
                      
                    }) :
                    dependent.map((item, i) => {
                      if (cat[i] == false) {
                        return (
                          <Box
                            component={Paper}
                            elevation={5}
                            sx={{
                              width: "300px",
                              height: "260px",
                              border: 1,
                              borderColor: Projectcolors.primary,
                              marginLeft: 3,
                              marginTop: 1,
                            }}
                            className="resizeable"
                          >
                            <div
                              style={{
                                backgroundColor: Projectcolors.primary,
                                height: 28,
                                padding: 4,
                              }}
                            >
                              <Typography
                                sx={{
                                  display: "inline",
                                  flex: 1,
                                  fontSize: 13,
                                  padding: 2,
                                  marginTop: 3,
                                  color: "white",
                                }}
                              >
                                {item.length > 20
                                  ? item.substring(0, 20) + "..."
                                  : item}
                              </Typography>
                              <TableViewIcon
                                sx={{
                                  display: "inline",
                                  float: "right",
                                  fontSize: 20,
                                  marginRight: 3,
                                  color: "white",
                                }}
                                onClick={() => handleClick(i)}
                              />
                              <ScatterPlotIcon
                                sx={{
                                  display: "inline",
                                  float: "right",
                                  fontSize: 20,
                                  marginRight: 3,
                                  color: "white",
                                }}
                                onClick={() => handleClick(i)}
                              />
                            </div>

                            <div>
                              {status[i] ? (
                                <div>
                                  {timeseries ? (
                                    <Plot
                                      data={[
                                        {
                                          x: xvalues[i],
                                          y: finallist[i],
                                          type: "scatter",
                                          mode: "markers",
                                          marker: {
                                            color: Projectcolors.primary,
                                          },
                                        },
                                      ]}
                                      layout={{
                                        margin: { b: 50, l: 80, r: 10, t: 20 },
                                        width: 260,
                                        height: 200,
                                        title: {
                                          font: {
                                            size: 24,
                                          },
                                          xref: "paper",
                                          x: 0.05,
                                        },
                                        xaxis: {
                                          title: {
                                            text: "Dates",
                                            font: {
                                              size: 12,
                                              color: "#000",
                                            },
                                          },
                                          linewidth: 1,
                                          ticklen: 1,
                                          automargin: true,
                                        },
                                        yaxis: {
                                          title: {
                                            text: "Forecasted",
                                            font: {
                                              size: 12,
                                              color: "#000",
                                            },
                                          },
                                          linewidth: 1,
                                          ticklen: 5,
                                        },
                                      }}
                                      config={{ displayModeBar: false }}
                                    />
                                  ) : (
                                    <Plot
                                      data={[
                                        {
                                          x: xvalues,
                                          y: finallist[i],
                                          type: "scatter",
                                          mode: "markers",
                                          marker: {
                                            color: Projectcolors.primary,
                                          },
                                        },
                                      ]}
                                      layout={{
                                        margin: { b: 50, l: 100, r: 10, t: 20 },
                                        width: 260,
                                        height: 200,
                                        title: {
                                          font: {
                                            size: 24,
                                          },
                                          xref: "paper",
                                          x: 0.05,
                                        },
                                        xaxis: {
                                          title: {
                                            text: "Input",
                                            font: {
                                              size: 12,
                                              color: "#000",
                                            },
                                          },
                                          linewidth: 1,
                                          ticklen: 10,
                                        },
                                        yaxis: {
                                          title: {
                                            text: "Predicted",
                                            font: {
                                              size: 12,
                                              color: "#000",
                                            },
                                          },
                                          linewidth: 1,
                                          ticklen: 5,
                                        },
                                      }}
                                      config={{ displayModeBar: false }}
                                    />
                                  )}
                                </div>
                              ) : (
                                <TableContainer
                                  component={Paper}
                                  elevation={2}
                                  style={{
                                    width: 240,
                                    height: 200,
                                    padding: 0,
                                    display: "block",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    marginTop: 12,
                                    border: 1,
                                    borderWidth: "2px",
                                    borderColor: "black",
                                  }}
                                >
                                  <Table
                                    aria-label="sticky table"
                                    stickyHeader
                                    sx={{ color: "black" }}
                                  >
                                    <TableHead
                                      sx={{
                                        backgroundColor: Projectcolors.primary,
                                        height: 10,
                                      }}
                                    >
                                      <TableRow>
                                        <TableCell
                                          sx={{
                                            padding: "7px",
                                            fontSize: 11,
                                            color: "white",
                                            fontWeight: "bold",
                                            backgroundColor:
                                              Projectcolors.primary,
                                          }}
                                        >
                                          Input
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "7px",
                                            fontSize: 11,
                                            color: "white",
                                            fontWeight: "bold",
                                            backgroundColor:
                                              Projectcolors.primary,
                                          }}
                                        >
                                          Predicted{" "}
                                        </TableCell>

                                        <TableCell
                                          sx={{
                                            // padding: "7px",
                                            width: 5,
                                            color: "white",
                                            fontWeight: "bold",
                                            backgroundColor:
                                              Projectcolors.primary,
                                          }}
                                        >
                                          <DownloadIcon
                                            sx={{
                                              fontSize: "small",
                                              marginTop: 1,
                                              marginRight: 2,
                                            }}
                                            onClick={() => {
                                              timeseries
                                                ? downloadCSV(
                                                    item,
                                                    xvalues[i],
                                                    finallist[i],
                                                    timeseries
                                                  )
                                                : downloadCSV(
                                                    item,
                                                    xvalues,
                                                    finallist[i],
                                                    timeseries
                                                  );
                                            }}
                                          />
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    {timeseries ? (
                                      <TableBody>
                                        {xvalues[i].map((x, j) => {
                                          return (
                                            <TableRow>
                                              <TableCell
                                                sx={{
                                                  fontSize: 11,
                                                  padding: "7px",
                                                }}
                                              >
                                                {x}
                                              </TableCell>

                                              <TableCell
                                                sx={{
                                                  fontSize: 11,
                                                  padding: "7px",
                                                }}
                                              >
                                                {finallist[i][j]
                                                  .toString()
                                                  .startsWith("0.") ||
                                                finallist[i][j]
                                                  .toString()
                                                  .startsWith("-0.") ? (
                                                  <div>
                                                    {finallist[i][
                                                      j
                                                    ].toExponential(3)}
                                                  </div>
                                                ) : (
                                                  <div>
                                                    {finallist[i][j].toFixed(3)}
                                                  </div>
                                                )}
                                              </TableCell>
                                              <TableCell></TableCell>
                                            </TableRow>
                                          );
                                        })}
                                      </TableBody>
                                    ) : (
                                      <TableBody>
                                        {xvalues.map((x, j) => {
                                          return (
                                            <TableRow>
                                              <TableCell
                                                sx={{
                                                  fontSize: 11,
                                                  padding: "7px",
                                                }}
                                              >
                                                {x
                                                  .toString()
                                                  .startsWith("0.") ||
                                                x
                                                  .toString()
                                                  .startsWith("-0.") ? (
                                                  <div>
                                                    {x.toExponential(3)}
                                                  </div>
                                                ) : (
                                                  <div>{x.toFixed(3)}</div>
                                                )}
                                              </TableCell>

                                              <TableCell
                                                sx={{
                                                  fontSize: 11,
                                                  padding: "7px",
                                                }}
                                              >
                                                {finallist[i][j]}
                                              </TableCell>
                                              <TableCell></TableCell>
                                            </TableRow>
                                          );
                                        })}
                                      </TableBody>
                                    )}
                                  </Table>
                                </TableContainer>
                              )}
                            </div>
                          </Box>
                        );
                      }
                    })} 
                  </Box>
                </div>
              </Box>
            </div>

            <Box
              component={Paper}
              elevation={10}
              sx={{ width: 450, marginLeft: 15 }}
            >
              <div
                className="content"
                style={{ backgroundColor: "#e2e2e2", padding: 10 }}
              >
                <Typography sx={{ fontSize: 15 }}>Selected Snapshot</Typography>
              </div>

              <div style={{ padding: 5 }}>
                <div>
                  <Box
                    component={Paper}
                    elevation={2}
                    sx={{
                      width: "340px",
                      height: "550px",
                      border: 2,
                      marginLeft: 1,
                      marginTop: 2,
                      overflowX: "scroll",
                      overflowY: "scroll",
                      padding: 2,
                    }}
                  >
                    <Typography sx={{ fontSize: 14, display: "inline" }}>
                      Snapshot {selectedsnapshot}
                    </Typography>

                    <Divider />

                    <Typography sx={{ fontSize: 11, padding: 0.5 }}>
                      Total Variable : {snapdetails.length}
                    </Typography>
                    {snapdetails.length > 0 ? (
                      <div>
                        {typeof snapdetails[0][1] !== "string" ? (
                          <div>
                            <Typography sx={{ fontSize: 11, padding: 0.5 }}>
                              Train Data % : {snapdetails[0][9][0]}
                            </Typography>
                            <Typography sx={{ fontSize: 11, padding: 0.5 }}>
                              No of Layers : {snapdetails[0][9][1]}
                            </Typography>
                            <Typography sx={{ fontSize: 11, padding: 0.5 }}>
                              No of Epochs : {snapdetails[0][9][2]}
                            </Typography>
                            <Typography sx={{ fontSize: 11, padding: 0.5 }}>
                              Batch Size : {snapdetails[0][9][3]}
                            </Typography>
                          </div>
                        ) : null}
                      </div>
                    ) : null}

                    {snapdetails.length > 0 ? (
                      <div>
                        {snapdetails.map((item, i) => {
                          if (cat[i] == false) {
                            return (
                              <div>
                                <Typography
                                  sx={{
                                    fontSize: 11,
                                    padding: 0.5,
                                    marginTop: 1.2,
                                  }}
                                >
                                  Dependent variable : {item[0]}
                                </Typography>
                                <Typography sx={{ fontSize: 11, padding: 0.5 }}>
                                  Machine Learning Method :{" "}
                                  {typeof item[1] === "string"
                                    ? item[1]
                                    : "ANN"}
                                </Typography>

                                <Typography sx={{ fontSize: 11, padding: 0.5 }}>
                                  R2(Training) Score : {item[5]}
                                </Typography>

                                <Typography sx={{ fontSize: 11, padding: 0.5 }}>
                                  R2(Training) - RMSE : {item[6]}
                                </Typography>
                                {timeseries ? null : (
                                  <Typography
                                    sx={{ fontSize: 11, padding: 0.5 }}
                                  >
                                    R2(Testing) Score : {item[7]}
                                  </Typography>
                                )}

                                {timeseries ? null : (
                                  <Typography
                                    sx={{ fontSize: 11, padding: 0.5 }}
                                  >
                                    R2(Testing) - RMSE : {item[8]}
                                  </Typography>
                                )}

                                <Divider />
                              </div>
                            );
                          } else {
                            return (
                              <div>
                                <Typography
                                  sx={{
                                    fontSize: 11,
                                    padding: 0.5,
                                    marginTop: 1.2,
                                  }}
                                >
                                  Dependent variable : {item[0]}
                                </Typography>
                                <Typography sx={{ fontSize: 11, padding: 0.5 }}>
                                  Machine Learning Method :{" "}
                                  {typeof item[1] === "string"
                                    ? item[1]
                                    : "ANN"}
                                </Typography>

                                <Typography sx={{ fontSize: 11, padding: 0.5 }}>
                                  Precision : {item[6][0]}
                                </Typography>

                                <Typography sx={{ fontSize: 11, padding: 0.5 }}>
                                  Recall : {item[6][1]}
                                </Typography>
                                <Typography sx={{ fontSize: 11, padding: 0.5 }}>
                                  F1 Score : {item[7]}
                                </Typography>

                                <Typography sx={{ fontSize: 11, padding: 0.5 }}>
                                  Accuracy : {item[8]}
                                </Typography>

                                <Divider />
                              </div>
                            );
                          }
                        })}
                      </div>
                    ) : null}
                  </Box>
                </div>
              </div>
            </Box>
          </Box>
        </Box>
      )}
      {getLoader ? <GlobalLoader /> : null}
    </div>
  );
}
