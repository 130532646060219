import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { baseURL } from "../../Config/config";
import { IconButton } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import Projectcolors from "../../Utils/Colors";

const RiskModal = (props) => {
  const navigate = useNavigate();

  const [projectName, setProjectName] = React.useState("");
  const [unitName, setUnitName] = React.useState("");
  const [location, setLocation] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [attendees, setAttendees] = useState([
    { id: 1, name: "", position: "" },
   
  ]);
  const handleInputChange = (id, field, value) => {
    setAttendees((prev) =>
      prev.map((attendee) =>
        attendee.id === id ? { ...attendee, [field]: value } : attendee
      )
    );
  };

  const addAttendee = () => {
    setAttendees((prev) => [
      ...prev,
      { id: prev.length + 1, name: "", position: "" },
    ]);
  };

  const removeAttendee = (id) => {
    setAttendees((prev) => prev.filter((attendee) => attendee.id !== id));
  };

  const createNewSelexRiskProject = async () => {
    setIsLoading(true);
    const body = {
      projectName,
      unitName,
      location,
      time: new Date(),
      team : attendees,
      allnodes:[]
      

    };
    localStorage.setItem("projectName", projectName);
    localStorage.setItem("loaded", false);
   const token =  localStorage.getItem("usertoken");

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json","token":token },
      body: JSON.stringify(body), 
    };
    try {
      let res = await fetch(`${baseURL}/newselexriskproject`, requestOptions);
      res = await res.json();
      if (res.code) {
        localStorage.setItem("projectID",res.message)
        setIsLoading(false);
        navigate("/newselexriskproject", { state: { loaded: false, ProjectID: false } });
        return;
      }
      alert("Some Error");
      setIsLoading(false);
    } catch (error) {
      alert("Some Error", error);
      setIsLoading(false);
      console.log("err", error);
    }
  };


  return (
    <div style={{ backgroundColor: "red" }}>
      <Modal
        open={props.isModal}
        onClose={props.toggleModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid
          style={{
            backgroundColor: "#fff",
            minHeight: "450px",
            width: "370px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow: 24,
            flex: 1,
          }}
        >

          {" "}
          <Grid
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              height: "100%",
              flexDirection: "column",
              paddingTop: "30px",
            }}
          >

            {/* <input
              onChange={(e) => setProjectName(e.target.value)}
              style={{
                width: "80%",
                height: "1%",
                marginTop: "20px",
                padding: "20px 10px",
              }}
              inputProps={{ style: { fontSize: 12,height:8 } }}
              placeholder="Projects name"
            /> */}
            <Typography style={{fontSize: "12px" }}>Enter Project Name  </Typography>

            <TextField
            //   required
            id="outlined-basic"
            variant="outlined"
            placeholder=""
            //   defaultValue="Hello World"
            onChange={(e) => setProjectName(e.target.value)}
            sx={{ width: 300, fontSize: "30px",width:"80%"}}
            InputLabelProps={{ style: { fontSize: 12 } }}
            inputProps={{ style: { fontSize: 12,height:"4px" } }}
            // InputLabelProps={{ shrink: true }}
          />
          <Typography style={{fontSize: "12px",marginTop:"12px" }}>Enter Unit Name </Typography>
          <TextField
            //   required
            id="outlined-basic"
            variant="outlined"
            placeholder=""
            //   defaultValue="Hello World"
            onChange={(e) => setUnitName(e.target.value)}
            sx={{ width: 300, fontSize: "30px",width:"80%"}}
            InputLabelProps={{ style: { fontSize: 12 } }}
            inputProps={{ style: { fontSize: 12,height:"4px" } }}
            // InputLabelProps={{ shrink: true }}
          />
          <Typography style={{fontSize: "12px",marginTop:"12px" }}>Enter Location</Typography>
          <TextField
            //   required
            id="outlined-basic"
            variant="outlined"
            placeholder=""
            //   defaultValue="Hello World"
            onChange={(e) => setLocation(e.target.value)}
            sx={{ width: 300, fontSize: "30px",width:"80%"}}
            InputLabelProps={{ style: { fontSize: 12 } }}
            inputProps={{ style: { fontSize: 12,height:"4px" } }}
            // InputLabelProps={{ shrink: true }}
          />
<Typography style={{fontSize: "12px",marginTop:"13px" }}>Team Composition </Typography>
<Box sx={{ maxWidth: 400, mx: "auto", mt: 1}}>
      {attendees.map((attendee) => (
        <Box key={attendee.id} sx={{ display: "flex", gap: 1, mb: 2 }}>
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            value={attendee.name}
            onChange={(e) => handleInputChange(attendee.id, "name", e.target.value)}
          />
          <TextField
            label="Position"
            variant="outlined"
            fullWidth
            value={attendee.position}
            onChange={(e) => handleInputChange(attendee.id, "position", e.target.value)}
          />
            <IconButton onClick={() => removeAttendee(attendee.id)} color="error">
              <Delete />
            </IconButton>
          
        </Box>
      ))}
     
     
                   <Button 
        onClick={addAttendee} 
        variant="contained" 
        startIcon={<Add sx={{ border: "1px solid #ccc", padding: "2px" }} />} 
         
        sx={{  borderRadius: "2px" , width:"120px",height: "30px",float:"right" }}
      >
        Add More
      </Button>
    </Box>
    <Box sx={{width:"310px",mb:2}}>
    {isLoading ? (
                    <Box
                      sx={{
                        position: "absolute",
                        right: "35px",
                        bottom: "30px",
                        height: "30px",
                        fontSize:"10px"
                      }}
                    >
                      <CircularProgress  size={28}/>
                    </Box>
                  ) : (
                    <Button
                      disabled={!projectName ? true : false}
                      onClick={() => createNewSelexRiskProject()}
                      sx={{
                        //position: "absolute",
                        float:"right",
                        //right: "35px",
                        //bottom: "30px",
                        height: "30px",
                        mt:2,
                    
                        fontSize:"10px"
                      }}
                      variant="contained"
                    >
                      Continue
                    </Button>
                  )}

    </Box>



            
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
};

export default RiskModal;
