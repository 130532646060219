import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Projectcolors from "../Utils/Colors";
import { baseURL } from "../Config/config";
import { Link, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Button } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Alert } from "@mui/material";
import { Paper } from "@mui/material";
import TextField from "@mui/material/TextField";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import GlobalLoader from "./CommonC/GlobalLoader";
import Tooltip from "@mui/material/Tooltip";
import model from "../images/model.png";
import Arrow from "../images/arrow.png";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';
import axios from "axios";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2"  sx={{fontSize:14}} color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};



function Forecasting(props) {
  const [independent, setIndependent] = React.useState([]);
  const [dependent, setDependent] = React.useState([]);
  const [count, setCount] = React.useState([]);
  const [dependentalgo, setDependentAlgo] = React.useState([]);
  const [progress, setProgress] = React.useState(0);
  const [algorithm, setAlgorithm] = React.useState([
    "ar",
    "arima",
    "sarimax",
    "es",
    "prophet",
    "cnnlstm"
  ]);

  const [algorithmshort, setAlgorithmshort] = React.useState([
    "AR",
    "ARIMA",
    "SARIMAX",
    "ES",
    "PROPHET",
    "CNNLSTM"
  ]);

  const [index, setIndex] = React.useState();
  const [unitsin, setunitsin] = React.useState([]);
  const [unitsde, setunitsde] = React.useState([]);
  const [trained, setTrained] = React.useState(false);
  const [test, setTest] = React.useState(false);
  const [htest, sethTest] = React.useState(false);
  const [modalTraining, setmodalTraining] = React.useState(false);
  const [allmodel, setallmodel] = React.useState([]);
  const [prevalgo, setprevalgo] = React.useState([]);
  const [cat, setcat] = React.useState([]);
  const [getLoader, setGetLoader] = React.useState(false);
  const [ann, setAnn] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [openn, setOpenn] = React.useState(false);
  const [selected, setselected] = React.useState(0);
  

  const [parameters, setParameters] = React.useState(
    [
    [1, "c", false, "", "none"],
    ["(1,1,0)", "(0, 0, 0, 0)", "", true, true, false, 1, "", "none"],
    [
      "(2,0,0)",
      "(0, 0, 0, 0)",
      "",
      false,
      false,
      true,
      false,
      true,
      true,
      false,
      1,
    ],

    [
      "",
      false,
      "",
      "",
      "estimated",
      "",
      "",
      "",
      false,
      "",
      "none",
    ],
    [null],
    [
      5,32,100,'tanh', 'sigmoid', true, 'glorot_uniform', 'orthogonal', 'zeros', 
      "none", "none", "none", "none", "none", "none", "none", "none", 0.0, 0.0, "none", 
      false, false, false, false, false, 'auto'
  ]
  ]);
  const [parameterslabels, setParameterslabels] = React.useState([
    ["Lags", "Trend", "Seasonal", "Period", "Missing"],

    [
      "Order",
      "Seasonal Order",
      "Trend",
      "Enforce Stationarity",
      "Enforce Invertibility",
      "Concentrate Scale",
      "Trend Offset",
      "Frequency",
      "Missing",
    ],

    [
      "Order",
      "Seasonal Order",
      "Trend",
      "Measurement Error",
      "Time Varying Regression",
      "Mle Regression",
      "Simple Differencing",
      "Enforce Stationarity",
      "Enforce Invertibility",
      "Concentrate Scale",
      "Trend Offset",
    ],

    [
      "Trend",
      "Damped Trend",
      "Seasonal",
      "Seasonal periods",
      "Initialization Method",
      "Initial Level",
      "Initial Trend",
      "Initial Seasonal",
      "Use Boxcox",
      "Frequency",
      "Missing",
    ],

    ["No Paramters"],
    [
      'Epochs','Batch Size','units', 'activation', 'recurrent_activation', 'use_bias', 
      'kernel_initializer', 'recurrent_initializer', 'bias_initializer', 
      'unit_forget_bias', 'kernel_regularizer', 'recurrent_regularizer', 
      'bias_regularizer', 'activity_regularizer', 'kernel_constraint', 
      'recurrent_constraint', 'bias_constraint', 'dropout', 
      'recurrent_dropout', 'seed', 'return_sequences', 'return_state', 
      'go_backwards', 'stateful', 'unroll', 'use_cudnn'
  
  ]]);
  const [links, setlinks] = React.useState([
    "https://www.statsmodels.org/v0.12.2/generated/statsmodels.tsa.ar_model.AR.html",
    "https://www.statsmodels.org/dev/generated/statsmodels.tsa.arima.model.ARIMA.html",
    "https://www.statsmodels.org/dev/generated/statsmodels.tsa.statespace.sarimax.SARIMAX.html",
    "https://www.statsmodels.org/dev/examples/notebooks/generated/exponential_smoothing.html",
    "https://facebook.github.io/prophet/docs/quick_start.html"
  ]);
  const [choosenalgo, setChoosenalgo] = React.useState([]);
  const [showinput, setshowinput] = React.useState([]);
  const [algochoosen, setalgochoosen] = React.useState(false);
  const [valueerror, setvalueerror] = React.useState("");
  const [timeseries, settimeseries] = React.useState(0);
  const [value, setValue] = React.useState("1");
  const tooltip = [
    [
      "{None, int, list[int]}",
      '{"n", "c", "t", "ct"}',
      "bool",
      '{"None","int"}',
      '{"None", "drop","raise"}',
    ],
    [
      "tuple",
      "tuple",
      '{"n","c","t","ct"}',
      "boolean",
      "boolean",
      "boolean",
      "integer",
      "string",
      "string",
    ],

    [
      "tuple",
      "tuple",
      '{"n","c","t","ct"}',
      "boolean",
      "boolean",
      "boolean",
      "boolean",
      "boolean",
      "boolean",
      "boolean",
      "integer",
    ],

    [
      "{“add”, “mul”, “additive”, “multiplicative”, None}",
      "boolean",
      "{“add”, “mul”, “additive”, “multiplicative”, None}",
      "integer",
      '{"None","estimated","heuristic","legacy-heuristic","known"}',
      "float",
      "float",
      "array",
      '{"True", "False", "log", "float"}',
      "string",
      "string",
    ],

    [],
    ["Positive integer","Positive integer","Positive integer","Activation function to use. Default: hyperbolic tangent (tanh). If you pass None, no activation is applied (ie. 'linear' activation: a(x) = x).",
      "Activation function to use for the recurrent step. Default: sigmoid (sigmoid). If you pass None, no activation is applied (ie. 'linear' activation: a(x) = x).",
      "Boolean, (default True)",
      "Initializer for the kernel weights matrix, used for the linear transformation of the inputs. Default: 'glorot_uniform'.",
      "Initializer for the recurrent_kernel weights matrix, used for the linear transformation of the recurrent state.",
      "Initializer for the bias vector",
      "Boolean (default True)",
      "Regularizer function applied to the kernel weights matrix",
      "Regularizer function applied to the recurrent_kernel weights matrix",
      "Regularizer function applied to the bias vector",
      "Regularizer function applied to the output of the layer (its 'activation')",
      "Constraint function applied to the kernel weights matrix",
      "	Constraint function applied to the recurrent_kernel weights matrix.",
      "Constraint function applied to the bias vector",
      "Float between 0 and 1",
      "Float between 0 and 1",
      "Random seed for dropout.",
      "Boolean",
      "Boolean",
      "Boolean",
      "Boolean",
      "Boolean",
      "Whether to use a cuDNN-backed implementation. 'auto' will attempt to use cuDNN when feasible, and will fallback to the default implementation if not."

    ]
  ];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpenn = () => {
    setOpenn(true);
  };

  const handleClosee = () => {
    setOpenn(false);
  };
  const handleChangeHybrid = (event, newValue) => {
    setValue(newValue);
  };


  let newArray = [];
  let newparameters = [];
  let change = [];
  let timer;

  //dependentalgo =['lasso','lasso']
  //dependentalgo = ['linear','lasso']

  const handleChange = (event, idx) => {
    //setalgochoosen(true);
    console.log(event,idx)
    setIndex(event.target.value);
    let index = event.target.value;
    const dataArray = Array.from(dependentalgo);
    const chosenalgoarray = Array.from(choosenalgo);
    const show = Array.from(showinput);
    dataArray[idx] = algorithm[index];
    chosenalgoarray[idx] = parameters[index];
    show[idx] = index;
    setDependentAlgo(dataArray);
    setChoosenalgo(chosenalgoarray);
    setshowinput(show);

    // let dataArray =
    //index - e.target.value
    //item to update on index- algorithm[e.target.value]
    // setDependentAlgo(dependentalgo=>[...dependentalgo,algorithm[event.target.value]]);
  };

  const trainhmodel = () => {
    setProgress(0);
    setTrained(false);
    const ProjectID = localStorage.getItem("projectID");
    
    if (htest == "" || htest == null || dependentalgo.length == 0) {
      setOpen(true);
    }
     
    else {
      const body = JSON.stringify({
        independent,
        dependent,
        dependentalgo,
        trainsize: test,
        htrainsize:htest,
        ProjectID,
        allmodel,
        ann: 0,
        choosenalgo,
        showinput,
        timeseries: 1,
      });

      setmodalTraining(true);
      const config = {
        onUploadProgress: (progressEvent) => {
        let seconds = 1000    
        timer = setInterval(() => {
          setProgress((prevProgress) => (prevProgress >= 99 ? 99 : prevProgress + 1));
          
        }, seconds)
        
        },
         headers : {
          "Content-Type": "application/json",
        }
      }

      try {
        axios
        .post(`${baseURL}/trainmodel`, body,config)
          .then((response) => response)
          .then((result) => {
            if (result.data.message == "error") {
              console.log("errorr");
              console.log(result.data.algo)
              console.log(algorithmshort[result.data.algo])
              setselected(result.data.algo);
              setTrained(false);
              setmodalTraining(false);
              setProgress(0);
              setvalueerror(result.data.valueerror);
              setOpenn(true);
              
            } else {
              setTrained(true);
              setmodalTraining(false);
              setallmodel(result.data.allmodel);
              clearInterval(timer);
              setProgress(100);
              
            }
          })
          .catch((error) => {
            setmodalTraining(false);
            console.error("Error:", error);
          });
      } catch (error) {
        setmodalTraining(false);
        console.log("error", error);
      }
    }
  };

  const trainmodel = () => {
    setProgress(0);
    setTrained(false);
    const ProjectID = localStorage.getItem("projectID");
    
    if (test == "" || test == null || dependentalgo.length == 0) {
      setOpen(true);
    }
     
    else {
      const body = JSON.stringify({
        independent,
        dependent,
        dependentalgo,
        trainsize: test,
        ProjectID,
        allmodel,
        ann: 0,
        choosenalgo,
        showinput,
        timeseries: 1,
      });

      setmodalTraining(true);
      const config = {
        onUploadProgress: (progressEvent) => {
        let seconds = 1000    
        timer = setInterval(() => {
          setProgress((prevProgress) => (prevProgress >= 99 ? 99 : prevProgress + 1));
          
        }, seconds)
        
        },
         headers : {
          "Content-Type": "application/json",
        }
      }

      try {
        axios
        .post(`${baseURL}/trainmodel`, body,config)
          .then((response) => response)
          .then((result) => {
            if (result.data.message == "error") {
              console.log("errorr");
              console.log(result.data.algo)
              console.log(algorithmshort[result.data.algo])
              setselected(result.data.algo);
              setTrained(false);
              setmodalTraining(false);
              setProgress(0);
              setvalueerror(result.data.valueerror);
              setOpenn(true);
              
            } else {
              setTrained(true);
              setmodalTraining(false);
              setallmodel(result.data.allmodel);
              clearInterval(timer);
              setProgress(100);
              
            }
          })
          .catch((error) => {
            setmodalTraining(false);
            console.error("Error:", error);
          });
      } catch (error) {
        setmodalTraining(false);
        console.log("error", error);
      }
    }
  };

  const initializeDependentAlgo = (data) => {
    if (data.prevalgo.length > 0) {
      data.dependent.map((item, i) => {
        newArray.push(algorithm[data.prevalgo[i]]);
        setDependentAlgo(newArray);
      });
    } else {
      data.dependent.map((item, i) => {
        newArray.push("lasso");
        setDependentAlgo(newArray);
      });
    }
  };

  React.useEffect(() => {
    getBuildModel();
  }, []);

  const getBuildModel = () => {
    setGetLoader(true);
    const ProjectID = localStorage.getItem("projectID");
    fetch(
      `${baseURL}/getbuildmodel?projectID=${ProjectID}&loaded=${props.loaded}`
    )
      .then((res) => res.json())
      .then((data) => {
        setTest(data.traindata);
        setprevalgo(data.prevalgo);
        initializeDependentAlgo(data);
        setIndependent(data.independent);
        setDependent(data.dependent);
        setunitsin(data.unitsin);
        setunitsde(data.unitsde);
        setCount(data.len_all);
        setcat(data.cat);
        setGetLoader(false);
        setAnn(data.ann);
        setshowinput(data.showinput);
        setChoosenalgo(data.hyperparameters);
        settimeseries(data.timeseries);

        //setIndex(0);
      })
      .catch((err) => {
        setGetLoader(false);
        console.log("err", err);
      });
  };

  const NextStep = () => {
    props.handleNextStep();
  };
  const handleSize = (e) => {
    setTest(e.target.value);
  };

  const handlehSize = (e) => {
    sethTest(e.target.value);
  };

  const handleInput = (event, i, j) => {
    let change = choosenalgo.slice();
    let arrayy = change[i].slice();
    arrayy[j] = event.target.value;
    change[i] = arrayy.slice();
    setChoosenalgo(change);

    //change[i][j] = event.target.value;
    //setchoosenalgo(chosenalgoarray);
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ color: "red", fontSize: "13px" }}
        >
          {"Train Data % Required"}
        </DialogTitle>
      </Dialog>

      <Dialog
        open={openn}
        onClose={handleClosee}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ color: "red", fontSize: "13px" }}
        >
          <Typography sx={{ fontSize: 15 }}>
            Parameter Input Wrong for Algorithm {algorithmshort[selected]}{" "}
          </Typography>
          <Typography sx={{ fontSize: 13, color: "black", marginTop: 2 }}>
            {valueerror}
          </Typography>
          <Typography
            sx={{ fontSize: 13, color: Projectcolors.primary, marginTop: 2 }}
          >
            Know more about {algorithmshort[selected]} Parameters:{" "}
            <a target="_blank" href={links[selected]}>
              Click Here
            </a>
          </Typography>
        </DialogTitle>
      </Dialog>
      {timeseries ? (
        <Box
        component={Paper}
        elevation={10}
        m="auto"
        sx={{
          height: "675px",
          width: 1480,
          marginTop: "1px",
          padding: "28px 32px",
          // paddingBottom:10,
          marginBottom: 10,
        }}
      >
         <TabContext value={value}>
         <Box
           sx={{ borderBottom: 1, borderColor: "divider" }}
           display="flex"
           justifyContent="center"
           alignItems="center"
         >
           <TabList onChange={handleChangeHybrid} aria-label="lab API tabs example">
             <Tab
               label="Classic Time Series"
               value="1"
               sx={{
                 fontSize: "17px",
                 textTransform: "none",
                 fontWeight: "bold",
               }}
             />
             <Tab
               label="Hybrid Time Series"
               value="2"
               sx={{
                 fontSize: "17px",
                 textTransform: "none",
                 fontWeight: "bold",
               }}
             />
            
           </TabList>
         </Box>
         <TabPanel value="1">
         <Box
            sx={{
              float: "right",
              marginRight: 8,
              width: "300px",
              border: 0,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography
              component="div"
              sx={{
                fontSize: "13px",
                ml: "70px",
                mr: "8px  ",
                fontWeight: "bold",
              }}
            >
              Train Data %
            </Typography>
            {test > 0 && ann.length === 0 ? (
              <FormControl
                sx={{ float: "right", width: 140, marginTop: 0, padding: 0 }}
              >
                {" "}
                <OutlinedInput
                  sx={{ width: "147px", fontSize: "12px", height: "40px" }}
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Size"
                  onChange={handleSize}
                  size="small"
                  defaultValue={test}
                />
              </FormControl>
            ) : (
              <FormControl
                sx={{ float: "right", width: 140, marginTop: 0, padding: 0 }}
              >
                <OutlinedInput
                  id="outlined-basic"
                  variant="outlined"
                  sx={{ width: "147px", fontSize: "12px", height: "40px" }}
                  placeholder="Size"
                  onChange={handleSize}
                  size="small"
                  //  inputProps={:}
                />
              </FormControl>
            )}
          </Box>

          <Box
            //  m="auto"
            sx={{
              width: 1350,
              height: 340,
              display: "flex",
              flexDirection: "row",
              marginTop: "55px",
              boder: 1,
              //  bgcolor:"green"
              //  overflowX:"hidden"
              //  marginLeft:"50px"
            }}
          >
            <Box
              sx={{
                width: 250,
                height: "300px",
                marginTop: "0px",
                marginRight: "10px",
              }}
            >
              <div
                style={{
                  position: "fixed",
                  backgroundColor: "white",
                  position: "relative",
                  top: 8,
                  left: 15,
                  width: 155,
                }}
              >
                <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                  &nbsp;&nbsp;Independent Variables
                </Typography>
              </div>

              <Box
                sx={{
                  width: 250,
                  height: "300px",
                  marginTop: "0px",
                  border: 1,
                  borderColor: Projectcolors.primary,
                  padding: "10px 30px",
                  overflowY: "scroll",
                  // marginLeft: "1px",
                }}
              >
                {independent.map((item, i) => {
                  return (
                    <Typography
                      sx={{
                        // float: "right",
                        // marginRight: 0,
                        fontSize: "11px",
                        marginTop: 1,
                        // marginLeft: 5,
                      }}
                    >
                      {" "}
                      {item} ({unitsin[i]})
                      {/* <div style={{display:"flex",flexDirection:"row",alignItems:"center"}}>
               <Divider
                 sx={{
                   width: 240,
                   height: 1,
                   color: Projectcolors.primary,
                   backgroundColor: Projectcolors.primary,
                 }}
               />
               </div> */}
                      <img
                        src={Arrow}
                        style={{
                          width: "100%",
                          height: "28px",
                          marginTop: 1,
                          // backgroundColor:"red"
                        }}
                      />
                    </Typography>
                  );
                })}
              </Box>
            </Box>

            <Box
              sx={{
                width: 350,
                height: "300px",
                border: 1,
                padding: 5,
                boxShadow: 9,
                border: 1,
                borderWidth: "4px",
                borderColor: Projectcolors.primary,
                borderRadius: 3,
                fontSize: 20,
                textAlign: "center",
                marginTop: 2,
              }}
            >
              <Typography sx={{ fontSize: "15px" }}>
                SelexMB Training Model
              </Typography>
              <img
                src={model}
                style={{ marginTop: 40, objectFit: "contain", height: "140px" }}
              />
            </Box>

            <Box sx={{ width: 390, height: "300px", marginLeft: "10px" }}>
              <div
                style={{
                  position: "fixed",
                  backgroundColor: "white",
                  position: "relative",
                  top: 8,
                  left: 15,
                  width: 139,
                }}
              >
                <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                  &nbsp;&nbsp;Dependent Variables
                </Typography>
              </div>
              <Box
                sx={{
                  width: 370,
                  height: "300px",
                  marginTop: "0px",
                  border: 1,
                  borderColor: Projectcolors.primary,
                  padding: 1,
                  overflowY: "scroll",
                  marginLeft: "1px",
                }}
              >
                {dependent.map((item, i) => {
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: 0,
                        width: 180,
                      }}
                    >
                      <Box>
                        <Typography sx={{ fontSize: "11px", marginLeft: 3 }}>
                          {item} ({unitsde[i]})
                          {/* <ArrowRightAltIcon sx={{fontSize:27,marginTop:0,marginleft:30,position:"absolute"}}/> */}
                        </Typography>
                        <Typography>
                          <img
                            src={Arrow}
                            style={{
                              width: "165px",
                              height: "28px",
                              marginTop: 1,
                              marginLeft: "10px",
                              marginRight: "10px",
                              // backgroundColor:"red"
                            }}
                          />
                        </Typography>
                      </Box>
                      <Box>
                        <div>
                          <FormControl
                            size="large"
                            sx={{ width: 150, marginTop: "20px" }}
                          >
                            <Typography sx={{ fontSize: "10px" }}>
                              {" "}
                              Select Algorithm
                            </Typography>
                            <Select
                              id="demo-simple-select-helper"
                              // label="Age"
                              //label=""
                              placeholder="Placeholder"
                              // labelId={item}
                              // id={i}
                              //value={algorithm[index]}
                              //label={item}
                              sx={{ fontSize: "12px", height: "43px" }}
                              onChange={(e) => {
                                handleChange(e, i);
                              }}
                              defaultValue={prevalgo[i]}
                              //  inputProps={{height:"20px"}}
                              //defaultValue={1}
                            >
                              <MenuItem value={0} sx={{ fontSize: "11px" }}>
                                AR
                              </MenuItem>
                              <MenuItem value={1} sx={{ fontSize: "11px" }}>
                                ARIMA
                              </MenuItem>
                              <MenuItem value={2} sx={{ fontSize: "11px" }}>
                                SARIMAX
                              </MenuItem>
                              <MenuItem value={3} sx={{ fontSize: "11px" }}>
                                ES
                              </MenuItem>
                              <MenuItem value={4} sx={{ fontSize: "11px" }}>
                                PROPHET
                              </MenuItem>
                             
                            </Select>
                          </FormControl>
                        </div>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>

            <Box>
              <div
                style={{
                  backgroundColor: "white",
                  position: "relative",
                  top: 10,
                  left: 15,
                  width: 160,
                }}
              >
                <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                  &nbsp;&nbsp;Hyperparameter Tuning
                </Typography>
              </div>

              <Box
                sx={{
                  border: 1,
                  borderColor: Projectcolors.primary,
                  width: 340,
                  marginTop: 0,
                  marginLeft: 0,
                  marginRight: 1,
                  height: "420px",
                  padding: 1,
                  overflowY: "scroll",
                }}
              >
                {choosenalgo.map((item, i) => {
                  return (
                    <div style={{ marginTop: "8px" }}>
                      <Typography
                        sx={{
                          fontSize: "13px",
                          marginTop: 10,
                          display: "inline",
                        }}
                      >
                        {dependent[i]} {algorithmshort[showinput[i]]} Parameters
                      </Typography>
                      <Divider sx={{ marginBottom: 2 }} />
                      {algorithmshort[showinput[i]] != "PROPHET" ? (
                        <div>
                          {item.map((value, j) => {
                            return (
                              <div>
                                <Box
                                  sx={{ display: "flex", flexDirection: "row" }}
                                >
                                  <Box sx={{ width: 155, padding: 1 }}>
                                    <Typography sx={{ fontSize: "12px" }}>
                                      {parameterslabels[showinput[i]][j]}
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <FormControl>
                                      {" "}
                                      <OutlinedInput
                                        id="outlined-basic"
                                        variant="outlined"
                                        placeholder="None"
                                        sx={{
                                          fontSize: "11px",
                                          width: "130px",
                                        }}
                                        onChange={(e) => {
                                          handleInput(e, i, j);
                                        }}
                                        size="small"
                                        value={value}
                                        defaultValue={value}
                                      />
                                    </FormControl>
                                    <Tooltip
                                      title={
                                        <Typography fontSize={11}>
                                          {tooltip[showinput[i]][j]}
                                        </Typography>
                                      }
                                      sx={{ fontSize: 15 }}
                                    >
                                      <IconButton>
                                        <InfoIcon fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                  </Box>
                                </Box>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <div>
                          <Typography sx={{ fontSize: "12px" }}>
                            No hyperparameters for Prophet Algorithm
                          </Typography>
                        </div>
                      )}
                    </div>
                  );
                })}
              </Box>
            </Box>
          </Box>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              margin: "auto",
              width: "30%",
              // marginTop: 6,
              marginLeft: "18%",
            }}
          >
            <h5 style={{ fontSize: "13px" }}>Count : {count} </h5>
            <h5 style={{ fontSize: "13px" }}>
              Independent : {independent.length}
            </h5>
            <h5 style={{ fontSize: "13px" }}>Dependent : {dependent.length}</h5>
          </div>

          <Box sx={{height:"55px"}}>
            
          <Button
              onClick={trainmodel}
              disabled={!props.editAccess}
              mr={4}
              sx={{
                width: "95px",
                height: "32px",
                fontSize: "12px",
                backgroundColor: Projectcolors.primary,
                color: "#fff",
                fontStyle: "bold",
                // marginBottom: 2,
                float: "left",
                marginRight: 3,
                marginLeft: "270px",
                mt: 1,
                fontWeight: "bold",
                // marginBottom:40
                // marginTop: 2,
              }}
              variant="contained"
              size="medium"
            >
              {" "}
              Train
            </Button>
          
          <Button
            onClick={NextStep}
            mt={5}
            sx={{
              float: "left",
              width: 10,
              minWidth: 38,
              height: "32px",
              fontSize: "19px",
              // marginTop: 2,
              backgroundColor: Projectcolors.primary,
              color: "black",
              fontStyle: "bold",
              padding: 0,
              mt: 1,
              marginRight: 3,
            }}
            variant="contained"
            size="large"
          >
            {" "}
            <ChevronRightIcon sx={{ fontSize: "19px", color: "white" }} />
          </Button>

          {trained ? (
              <Alert
                sx={{
                  width: "20%",
                  fontSize: "12px",
                  color: Projectcolors.primary,
                  marginLeft: "340px",
                  marginTop: "7px",
                }}
                variant="outlined"
                severity="success"
              >
                Model Trained Successfully
              </Alert>
          ) : (
            null
          )}


          </Box>


        {trained?(
           <Box sx={{ width: '100%',marginBottom:0,height:"20px",marginTop:"15px"}}>
             <LinearProgressWithLabel value={100} sx={{height:"6px"}} />
             </Box>
         ):(null)

         }
        
      {modalTraining?(
          <Box sx={{ width: '100%',marginBottom:0,height:"10px",marginTop:"20px"}}>
            <LinearProgressWithLabel value={progress} sx={{height:"6px"}} />
            
          
          </Box>
          ):(null)}
          </TabPanel>
          <TabPanel value="2">
          <Box
            sx={{
              float: "right",
              marginRight: 8,
              width: "300px",
              border: 0,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography
              component="div"
              sx={{
                fontSize: "13px",
                ml: "70px",
                mr: "8px  ",
                fontWeight: "bold",
              }}
            >
              Train Data %
            </Typography>
            {htest > 0 && ann.length === 0 ? (
              <FormControl
                sx={{ float: "right", width: 140, marginTop: 0, padding: 0 }}
              >
                {" "}
                <OutlinedInput
                  sx={{ width: "147px", fontSize: "12px", height: "40px" }}
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Size"
                  onChange={handlehSize}
                  size="small"
                  defaultValue={htest}
                />
              </FormControl>
            ) : (
              <FormControl
                sx={{ float: "right", width: 140, marginTop: 0, padding: 0 }}
              >
                <OutlinedInput
                  id="outlined-basic"
                  variant="outlined"
                  sx={{ width: "147px", fontSize: "12px", height: "40px" }}
                  placeholder="Size"
                  onChange={handlehSize}
                  size="small"
                  //  inputProps={:}
                />
              </FormControl>
            )}
          </Box>

          <Box
            //  m="auto"
            sx={{
              width: 1350,
              height: 340,
              display: "flex",
              flexDirection: "row",
              marginTop: "55px",
              boder: 1,
              //  bgcolor:"green"
              //  overflowX:"hidden"
              //  marginLeft:"50px"
            }}
          >
            <Box
              sx={{
                width: 250,
                height: "300px",
                marginTop: "0px",
                marginRight: "10px",
              }}
            >
              <div
                style={{
                  position: "fixed",
                  backgroundColor: "white",
                  position: "relative",
                  top: 8,
                  left: 15,
                  width: 155,
                }}
              >
                <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                  &nbsp;&nbsp;Independent Variables
                </Typography>
              </div>

              <Box
                sx={{
                  width: 250,
                  height: "300px",
                  marginTop: "0px",
                  border: 1,
                  borderColor: Projectcolors.primary,
                  padding: "10px 30px",
                  overflowY: "scroll",
                  // marginLeft: "1px",
                }}
              >
                {independent.map((item, i) => {
                  return (
                    <Typography
                      sx={{
                        // float: "right",
                        // marginRight: 0,
                        fontSize: "11px",
                        marginTop: 1,
                        // marginLeft: 5,
                      }}
                    >
                      {" "}
                      {item} ({unitsin[i]})
                      {/* <div style={{display:"flex",flexDirection:"row",alignItems:"center"}}>
               <Divider
                 sx={{
                   width: 240,
                   height: 1,
                   color: Projectcolors.primary,
                   backgroundColor: Projectcolors.primary,
                 }}
               />
               </div> */}
                      <img
                        src={Arrow}
                        style={{
                          width: "100%",
                          height: "28px",
                          marginTop: 1,
                          // backgroundColor:"red"
                        }}
                      />
                    </Typography>
                  );
                })}
              </Box>
            </Box>

            <Box
              sx={{
                width: 350,
                height: "300px",
                border: 1,
                padding: 5,
                boxShadow: 9,
                border: 1,
                borderWidth: "4px",
                borderColor: Projectcolors.primary,
                borderRadius: 3,
                fontSize: 20,
                textAlign: "center",
                marginTop: 2,
              }}
            >
              <Typography sx={{ fontSize: "15px" }}>
                SelexMB Training Model
              </Typography>
              <img
                src={model}
                style={{ marginTop: 40, objectFit: "contain", height: "140px" }}
              />
            </Box>

            <Box sx={{ width: 390, height: "300px", marginLeft: "10px" }}>
              <div
                style={{
                  position: "fixed",
                  backgroundColor: "white",
                  position: "relative",
                  top: 8,
                  left: 15,
                  width: 139,
                }}
              >
                <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                  &nbsp;&nbsp;Dependent Variables
                </Typography>
              </div>
              <Box
                sx={{
                  width: 370,
                  height: "300px",
                  marginTop: "0px",
                  border: 1,
                  borderColor: Projectcolors.primary,
                  padding: 1,
                  overflowY: "scroll",
                  marginLeft: "1px",
                }}
              >
                {dependent.map((item, i) => {
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: 0,
                        width: 180,
                      }}
                    >
                      <Box>
                        <Typography sx={{ fontSize: "11px", marginLeft: 3 }}>
                          {item} ({unitsde[i]})
                          {/* <ArrowRightAltIcon sx={{fontSize:27,marginTop:0,marginleft:30,position:"absolute"}}/> */}
                        </Typography>
                        <Typography>
                          <img
                            src={Arrow}
                            style={{
                              width: "165px",
                              height: "28px",
                              marginTop: 1,
                              marginLeft: "10px",
                              marginRight: "10px",
                              // backgroundColor:"red"
                            }}
                          />
                        </Typography>
                      </Box>
                      <Box>
                        <div>
                          <FormControl
                            size="large"
                            sx={{ width: 150, marginTop: "20px" }}
                          >
                            <Typography sx={{ fontSize: "10px" }}>
                              {" "}
                              Select Algorithm
                            </Typography>
                            <Select
                              id="demo-simple-select-helper"
                              // label="Age"
                              //label=""
                              placeholder="Placeholder"
                              // labelId={item}
                              // id={i}
                              //value={algorithm[index]}
                              //label={item}
                              sx={{ fontSize: "12px", height: "43px" }}
                              onChange={(e) => {
                                handleChange(e, i);
                              }}
                              defaultValue={prevalgo[i]}
                              //  inputProps={{height:"20px"}}
                              //defaultValue={1}
                            >
                              <MenuItem value={5} sx={{ fontSize: "11px" }}>
                                CNN LSTM
                              </MenuItem>
                              
                            </Select>
                          </FormControl>
                        </div>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>

            <Box>
              <div
                style={{
                  backgroundColor: "white",
                  position: "relative",
                  top: 10,
                  left: 15,
                  width: 160,
                }}
              >
                <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                  &nbsp;&nbsp;Hyperparameter Tuning
                </Typography>
              </div>

              <Box
                sx={{
                  border: 1,
                  borderColor: Projectcolors.primary,
                  width: 340,
                  marginTop: 0,
                  marginLeft: 0,
                  marginRight: 1,
                  height: "420px",
                  padding: 1,
                  overflowY: "scroll",
                }}
              >
                {choosenalgo.map((item, i) => {
                  return (
                    <div style={{ marginTop: "8px" }}>
                      <Typography
                        sx={{
                          fontSize: "13px",
                          marginTop: 10,
                          display: "inline",
                        }}
                      >
                        {dependent[i]} {algorithmshort[showinput[i]]} Parameters
                      </Typography>
                      <Divider sx={{ marginBottom: 2 }} />
                      {algorithmshort[showinput[i]] != "PROPHET" ? (
                        <div>
                          {item.map((value, j) => {
                            return (
                              <div>
                                <Box
                                  sx={{ display: "flex", flexDirection: "row" }}
                                >
                                  <Box sx={{ width: 155, padding: 1 }}>
                                    <Typography sx={{ fontSize: "12px" }}>
                                      {parameterslabels[showinput[i]][j]}
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <FormControl>
                                      {" "}
                                      <OutlinedInput
                                        id="outlined-basic"
                                        variant="outlined"
                                        placeholder="None"
                                        sx={{
                                          fontSize: "11px",
                                          width: "130px",
                                        }}
                                        onChange={(e) => {
                                          handleInput(e, i, j);
                                        }}
                                        size="small"
                                        value={value}
                                        defaultValue={value}
                                      />
                                    </FormControl>
                                    <Tooltip
                                      title={
                                        <Typography fontSize={11}>
                                          {tooltip[showinput[i]][j]}
                                        </Typography>
                                      }
                                      sx={{ fontSize: 15 }}
                                    >
                                      <IconButton>
                                        <InfoIcon fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                  </Box>
                                </Box>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <div>
                          <Typography sx={{ fontSize: "12px" }}>
                            No hyperparameters for Prophet Algorithm
                          </Typography>
                        </div>
                      )}
                    </div>
                  );
                })}
              </Box>
            </Box>
          </Box>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              margin: "auto",
              width: "30%",
              // marginTop: 6,
              marginLeft: "18%",
            }}
          >
            <h5 style={{ fontSize: "13px" }}>Count : {count} </h5>
            <h5 style={{ fontSize: "13px" }}>
              Independent : {independent.length}
            </h5>
            <h5 style={{ fontSize: "13px" }}>Dependent : {dependent.length}</h5>
          </div>

          <Box sx={{height:"55px"}}>
            
          <Button
              onClick={trainhmodel}
              disabled={!props.editAccess}
              mr={4}
              sx={{
                width: "95px",
                height: "32px",
                fontSize: "12px",
                backgroundColor: Projectcolors.primary,
                color: "#fff",
                fontStyle: "bold",
                // marginBottom: 2,
                float: "left",
                marginRight: 3,
                marginLeft: "270px",
                mt: 1,
                fontWeight: "bold",
                // marginBottom:40
                // marginTop: 2,
              }}
              variant="contained"
              size="medium"
            >
              {" "}
              Train
            </Button>
          
          <Button
            onClick={NextStep}
            mt={5}
            sx={{
              float: "left",
              width: 10,
              minWidth: 38,
              height: "32px",
              fontSize: "19px",
              // marginTop: 2,
              backgroundColor: Projectcolors.primary,
              color: "black",
              fontStyle: "bold",
              padding: 0,
              mt: 1,
              marginRight: 3,
            }}
            variant="contained"
            size="large"
          >
            {" "}
            <ChevronRightIcon sx={{ fontSize: "19px", color: "white" }} />
          </Button>

          {trained ? (
              <Alert
                sx={{
                  width: "20%",
                  fontSize: "12px",
                  color: Projectcolors.primary,
                  marginLeft: "340px",
                  marginTop: "7px",
                }}
                variant="outlined"
                severity="success"
              >
                Model Trained Successfully
              </Alert>
          ) : (
            null
          )}


          </Box>


        {trained?(
           <Box sx={{ width: '100%',marginBottom:0,height:"20px",marginTop:"15px"}}>
             <LinearProgressWithLabel value={100} sx={{height:"6px"}} />
             </Box>
         ):(null)

         }
        
      {modalTraining?(
          <Box sx={{ width: '100%',marginBottom:0,height:"10px",marginTop:"20px"}}>
            <LinearProgressWithLabel value={progress} sx={{height:"6px"}} />
            
          
          </Box>
          ):(null)}
          
            </TabPanel>
         </TabContext>
        
         


        </Box>
      ) : (
        <div>
          <Box
            component={Paper}
            elevation={10}
            m="auto"
            sx={{
              height: 600,
              width: 1350,
              marginTop: "20px",
              padding: 4,
            }}
          >
            <div style={{ margin: "auto", width: "100%", textAlign: "center" }}>
              <Typography
                sx={{
                  fontSize: 17,
                  color: Projectcolors.primary,
                  marginTop: 2,
                }}
              >
                Data Doesn't Include Time Series . Try MLOps and ANN Algorithms.
              </Typography>{" "}
            </div>
          </Box>
        </div>
      )}

      {getLoader ? <GlobalLoader /> : null}
    </div>
  );
}

export default Forecasting;
