import React from "react";
import Box from "@mui/material/Box";
import Projectcolors from "../Utils/Colors";
import { baseURL } from "../Config/config";
import { Typography, Link } from "@mui/material";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import OutlinedInput from "@mui/material/OutlinedInput";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import model from "../images/model.png";
import Arrow from "../images/arrow.png";

import Excel from "../images/excel.jpeg";
import Hysys from "../images/hysys.jpeg";
import Gui from "../images/optimize.jpeg";
import jsonimage from "../images/json.png";

import { makeStyles } from "@mui/styles";
import GlobalLoader from "./CommonC/GlobalLoader";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import LinearProgress from "@mui/material/LinearProgress";
import PropTypes from "prop-types";
import axios from "axios";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          sx={{ fontSize: 14 }}
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

const useStyles = makeStyles({
  button: {
    textTransform: "none",
  },
});

function Deploy(props) {
  const [independent, setIndependent] = React.useState([]);
  const [dependent, setDependent] = React.useState([]);
  const [values, setValues] = React.useState([]);
  const [getvalues, setgetValues] = React.useState([]);
  const [predicted, setPredicted] = React.useState([]);
  const [unitsin, setunitsin] = React.useState([]);
  const [unitsde, setunitsde] = React.useState([]);
  const [minimum, setminimum] = React.useState([]);
  const [maximum, setmaximum] = React.useState([]);
  const [isCompleted, setisCompleted] = React.useState(false);

  const [modalTraining, setmodalTraining] = React.useState(false);
  const [getLoader, setGetLoader] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [inputopen, setinputOpen] = React.useState(false);
  const [errdownload, seterrdownload] = React.useState(false);
  const [mess, setmess] = React.useState(false);
  const [fpmess, setfpmess] = React.useState(false);
  const [falsewarning, setfalsewarning] = React.useState(false);
  const [timeseries, settimeseries] = React.useState(0);
  const [date, setDate] = React.useState("");
  const [progress, setProgress] = React.useState(0);
  const [trained, setTrained] = React.useState(false);
  const [selectedsnapshot, setselectedsnapshot] = React.useState(0);
  const [snapdetails, setsnapdetails] = React.useState([]);
  const [cat, setcat] = React.useState([]);
  const [fp, setfp] = React.useState([])
  const [usefp, setusefp] = React.useState(false)

  const handledateChange = (event) => {
    setDate(event.target.value);
    console.log(event.target.value);
    const dataArray = Array.from(values);
    dataArray[0] = event.target.value;
    setValues(dataArray);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    seterrdownload(false);
  };

  const handleermessageOpen = () => {
    setmess(true);
  };
  const handleMessage = () => {
    setmess(false);
    seterrdownload(false);
  };
  const handlefpMessage = () => {
    setfpmess(false);
    seterrdownload(false);
  };

  const handleallClose = () => {
    setinputOpen(false);
    seterrdownload(false);
  };

  const handleerrClickOpen = () => {
    seterrdownload(true);
  };

  const handleerrClose = () => {
    seterrdownload(false);
  };

  const handlefalsewarningOpen = () => {
    setfalsewarning(true);
  };

  const handlefalsewarningClose = () => {
    setfalsewarning(false);
  };

  React.useEffect(() => {
    getDeployData();
    
  }, []);

  

  const getDeployData = () => {
    setGetLoader(true);
    if (props.loaded) {
      localStorage.setItem("projectID", props.ProjectID);
    }
    const projectID = localStorage.getItem("projectID");
    fetch(
      `${baseURL}/getdeploydata?projectID=${projectID}&loaded=${props.loaded}`
    )
      .then((res) => res.json())
      .then((data) => {
        console.log("data", data);
        if (data.predicted) {
          setPredicted(data.predicted);
          setgetValues(data.values);

          setValues(data.values);
        }
        setIndependent(data.independent);
        setDependent(data.dependent);
        setunitsin(data.unitsin);
        setunitsde(data.unitsde);
        setminimum(data.minimum);
        setmaximum(data.maximum);
        setisCompleted(data.isCompleted);
        settimeseries(data.timeseries);
        setusefp(data.usefp);
        setGetLoader(false);
        setselectedsnapshot(data.selectedsnapshot);
        setsnapdetails(data.snapdetails);
        setcat(data.cat);
        console.log(data);
        const projectID = localStorage.getItem("projectID");
        console.log("dataaaaaaa usefp",data.usefp)
        if(data.usefp == true){
          fetch(
            `${baseURL}/getfpData?projectID=${projectID}&loaded=${props.loaded}`
          )
            .then((res) => res.json())
            .then((data) => {
              
              setGetLoader(false);
              if (data.saved == 1){
                console.log("fp in sensitivity", data.fp);
                setfp(data.fp);
              }
  
              
            })
            .catch((err) => {
              setGetLoader(false);
            });
        }
        













      })
      .catch((err) => {
        setGetLoader(false);
      });
  };

  const handleChange = (event, idx) => {
    const dataArray = Array.from(values);
    dataArray[idx] = event.target.value;
    setValues(dataArray);
  };

  function checkinput() {
    var isnulll = false;
    if (values.length === 0 || values.length < independent.length) {
      setinputOpen(true);
      isnulll = true;
    }
    if (values.length === independent.length) {
      for (var i = 0; i < values.length; i++) {
        if (values[i] === "" || values[i] === null) {
          setinputOpen(true);
          isnulll = true;
        }
      }
    }
    if (isnulll === false) {
      predict();
    }
  }
  let timer;
  const predict = () => {
    const projectID = localStorage.getItem("projectID");
    console.log("In predict");
    console.log(date);
    const body = JSON.stringify({
      dependent,
      values,
      projectID,
      time: new Date(),
    });
    setProgress(0);
    setTrained(false);
    setmodalTraining(true);
    const config = {
      onUploadProgress: (progressEvent) => {
        let seconds = 1000;
        timer = setInterval(() => {
          setProgress((prevProgress) =>
            prevProgress >= 99 ? 99 : prevProgress + 1
          );
        }, seconds);
      },
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
      .post(`${baseURL}/predict?projectID=${projectID}`, body, config)
      .then((response) => response)
      .then((result) => {
        const data = result.data;
        if(data.message === "error")
        {
          setfpmess(true);
          setmodalTraining(false);
          clearInterval(timer);
          setTrained(false);

        }
        if (data.message === "timeserieserror") {
          setmess(true);
          setmodalTraining(false);
          clearInterval(timer);
          setTrained(false);
        } else if (data.predicted) {
          setPredicted(data.predicted);
          setmodalTraining(false);
          clearInterval(timer);
          setTrained(true);
          setProgress(100);
        } else {
          setfalsewarning(true);
          setmodalTraining(false);
          clearInterval(timer);
          setTrained(false);
        }
      })
      .catch((error) => {
        if (mess === false) {
          setfalsewarning(true);
        }
        clearInterval(timer);
        setTrained(false);

        setmodalTraining(false);
        console.error("Error:", error);
      });
  };

  function downloadReport() {
    const projectID = localStorage.getItem("projectID");
    const projectName = localStorage.getItem("projectName");
    setGetLoader(true);
    fetch(`${baseURL}/getreportdata?projectID=${projectID}`)
      .then((res) => res.blob())
      .then((blob) => {
        var file = new Blob([blob], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        var url = URL.createObjectURL(file);
        var link = document.createElement("a");
        link.download = projectName + ".xlsx";
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        fetch(`${baseURL}/deletefile?projectName=${projectName}`, {
          method: "POST",
          body: JSON.stringify(projectName),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())
          .then((result) => {
            setGetLoader(false);
          })
          .catch((error) => {
            console.error("Error:", error);
            setGetLoader(false);
          });
        setGetLoader(false);
      })
      .catch((err) => {
        setGetLoader(false);
        seterrdownload(true);
        console.log("Error", err);
      });
  }


  function downloadHysysFolder(){
    const projectID = localStorage.getItem("projectID");
    const projectName = localStorage.getItem("projectName");

    const user_token = localStorage.getItem("usertoken");
    setGetLoader(true);
    fetch(`${baseURL}/gethysyszipfile?projectID=${projectID}&user_token=${user_token}`)
      .then((res) => res.blob())
      .then((blob) => {
        var file = new Blob([blob]);
        var url = URL.createObjectURL(file);
        var link = document.createElement("a");
        link.download = projectName +".zip";
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        fetch(`${baseURL}/deletehysysfile?projectName=${projectName}`, {
          method: "POST",
          body: JSON.stringify(projectName),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())
          .then((result) => {
            setGetLoader(false);
          })
          .catch((error) => {
            console.error("In delete file Error:", error);
            seterrdownload(true);
            setGetLoader(false);
          });
        setGetLoader(false);
        /**
        var file = new Blob([blob], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        var url = URL.createObjectURL(file);
        var link = document.createElement("a");
        link.download = projectName + ".zip";
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setGetLoader(false);
        */

      })
      
      .catch((err) => {
        setGetLoader(false);
        seterrdownload(true);
        console.log("Error", err);
      });

  }


  function downloadExec(){
    const projectID = localStorage.getItem("projectID");
    const projectName = localStorage.getItem("projectName");

    const user_token = localStorage.getItem("usertoken");
    setGetLoader(true);
    fetch(`${baseURL}/getexeczipfile?projectID=${projectID}&user_token=${user_token}`)
      .then((res) => res.blob())
      .then((blob) => {
        var file = new Blob([blob],{
          type: "application/json",
        });
        console.log(file)
        var url = URL.createObjectURL(file);
        console.log(url)
        var link = document.createElement("a");
        console.log(link)
        link.download = projectName +".json";
        link.href = url;
        
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        fetch(`${baseURL}/deleteexefile?projectName=${projectName}`, {
          method: "POST",
          body: JSON.stringify(projectName),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())
          .then((result) => {
            setGetLoader(false);
          })
          .catch((error) => {
            console.error("In delete exe file Error:", error);
            seterrdownload(true);
            setGetLoader(false);
          });
        setGetLoader(false);
        
      })

      
      .catch((err) => {
        setGetLoader(false);
        seterrdownload(true);
        console.log("Error", err);
      });

      /**
      .then((blob) => {
        
        var file = new Blob([blob]);
        var url = URL.createObjectURL(file);
        var link = document.createElement("a");
        link.download = projectName +".zip";
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        
        setGetLoader(false);
        
      }) */
      

  }


  /**
  function downloadGui(){
    const projectID = localStorage.getItem("projectID");
    const projectName = localStorage.getItem("projectName");

    const user_token = localStorage.getItem("usertoken");
    setGetLoader(true);
    fetch(`${baseURL}/getguizipfile?projectID=${projectID}&user_token=${user_token}`)
      .then((res) => res.blob())
      .then((blob) => {
        var file = new Blob([blob]);
        console.log(file)
        var url = URL.createObjectURL(file);
        console.log(url)
        var link = document.createElement("a");
        console.log(link)
        
        //link.download = "Gui.zip";
        //link.href = url;
        link.href="https://drive.google.com/file/d/1Vue_PQybI0MV1GbpqO-UvQBougGju2Ar/view?usp=sharing"
        
        document.body.appendChild(link);
        link.click();
        //window.open(link.href, '_blank');
        
        document.body.removeChild(link);
        setGetLoader(false);
        
      })

      
      .catch((err) => {
        setGetLoader(false);
        seterrdownload(true);
        console.log("Error", err);
      });

      
      .then((blob) => {
        
        var file = new Blob([blob]);
        var url = URL.createObjectURL(file);
        var link = document.createElement("a");
        link.download = projectName +".zip";
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        
        setGetLoader(false);
        
      }) 
      

  }
  */




      /**
      .then((blob) => {
        
        var file = new Blob([blob]);
        var url = URL.createObjectURL(file);
        var link = document.createElement("a");
        link.download = projectName +".zip";
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        
        setGetLoader(false);
        
      }) */
      

  

  const classes = useStyles();

  return (
    <div style={{ marginTop: "1px" }}>
      <Dialog
        open={falsewarning}
        onClose={handlefalsewarningClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ color: "red", fontSize: "13px" }}
        >
          {"Prediction Failed"}
        </DialogTitle>
      </Dialog>

      <Dialog
        open={inputopen}
        onClose={handleallClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ color: "red", fontSize: "13px" }}
        >
          {"All Inputs require to predict new values"}
        </DialogTitle>
      </Dialog>
      <Dialog
        open={errdownload}
        onClose={handleerrClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ color: "red", fontSize: "13px" }}
        >
          {"Error Downloading !"}
        </DialogTitle>
      </Dialog>
      <Dialog
        open={mess}
        onClose={handleMessage}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ color: "red", fontSize: "13px" }}
        >
          {"The Given Date is out of Training Data Sample"}
        </DialogTitle>
      </Dialog>
      <Dialog
        open={fpmess}
        onClose={handlefpMessage}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ color: "red", fontSize: "13px" }}
        >
          {"Please Fix First Principles Calculations"}
        </DialogTitle>
      </Dialog>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ color: "red", fontSize: "13px" }}
        >
          {
            "            Incomplete Project ! Project Report Cannot be Downloaded"
          }
        </DialogTitle>
      </Dialog>

      <Box sx={{ border: 0 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            border: 0,
            marginTop: 1,
          }}
        >
          <Box
            component={Paper}
            elevation={10}
            //m="auto"
            sx={{
              height: 515,
              width: 1390,
              padding: 2,
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box sx={{ border: 0, marginTop: 1, mr: 2 }}>
              <TableContainer component={Paper} sx={{ height: 460 }}>
                <Table sx={{ minWidth: 295 }}>
                  <TableHead
                    sx={{
                      position: "sticky",
                      top: 0,
                      backgroundColor: Projectcolors.primary,
                    }}
                  >
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{ fontSize: "12px", color: "white" }}
                      >
                        Variable
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ fontSize: "12px", color: "white" }}
                      >
                        Minimum
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ fontSize: "12px", color: "white" }}
                      >
                        Maximum
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {minimum
                      ? minimum.map((row, i) => (
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              align="center"
                              sx={{ fontSize: "10px", height: 1 }}
                            >
                              {independent[i]}
                            </TableCell>

                            <TableCell
                              sx={{ fontSize: "10px", height: 1 }}
                              align="center"
                            >
                              {row}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ fontSize: "10px", height: 1 }}
                            >
                              {maximum[i]}
                            </TableCell>
                          </TableRow>
                        ))
                      : null}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                border: 0,
                width: 1030,
                height: 450,
              }}
            >
              {isCompleted ? (
                <div>
                 
                <div
                onClick={() => downloadReport()}
                style={{
                  //display: "flex",
                  //flexDirection: "row",
                  //alignItems: "center",
                  //border:"1px solid black",
                  float: "right",
                  ml: 100,
                  cursor: "pointer",
                }}
              >
                <Link
                  sx={{
                    fontSize: "13px",
                    fontStyle: "bold",
                    float: "right",
                    ml: 0,
                    mr: 2,
                    // marginTop: 0,
                    // marginBottom: 1,
                    textTransform: "none",
                  }}
                  variant="contained"
                  size="large"
                  textTransform="none"
                >
                   <img
                                      alt ="arrow"
                                      src={Excel}
                                      style={{
                                        marginTop: 0,
                                        marginBottom: 1,
                                        marginRight: 1,
                                        width:30,
                                        height:30
                                      }}
                                    />

                 {/*  <DownloadIcon
                    sx={{
                      fontSize: "medium",
                      float: "left",
                      marginTop: 0,
                      mb: 1,
                      mr: 0.5,
                      color: Projectcolors.primary,
                    }}
                  />*/}
                  <span style={{marginLeft:3}}>Download Project Report</span>
                </Link>
              </div>


              {timeseries?(null):( <div
                  onClick={() => downloadHysysFolder()}
                  style={{
                    //display: "flex",
                    //flexDirection: "row",
                    //alignItems: "center",
                    float: "right",
                    ml: 100,
                    cursor: "pointer",
                    marginTop:2
                  }}
                >
                  <Link
                    sx={{
                      fontSize: "13px",
                      fontStyle: "bold",
                      float: "right",
                      ml: 0,
                      mr: 0.5,
                      // marginTop: 0,
                      // marginBottom: 1,
                      textTransform: "none",
                    }}
                    variant="contained"
                    size="large"
                    textTransform="none"
                  >
                     <img
                                      alt ="arrow"
                                      src={Hysys}
                                      style={{
                                        marginTop: 0,
                                        marginBottom: 1,
                                        marginRight: 7,
                                        width:24,
                                        height:24
                                      }}
                                      />
                    <span style={{marginLeft:0,marginRight:3}}>Download HYSYS Extension</span>
                   
                  </Link>
                </div>)}


                {timeseries?(null):( <div
                  onClick={() => downloadExec()}
                  style={{
                    //display: "flex",
                    //flexDirection: "row",
                    //alignItems: "center",
                    float: "right",
                    ml: 100,
                    cursor: "pointer",
                    marginTop:2
                  }}
                >
                  <Link
                    sx={{
                      fontSize: "13px",
                      fontStyle: "bold",
                      float: "right",
                      ml: 0,
                      mr: 0.5,
                      // marginTop: 0,
                      // marginBottom: 1,
                      textTransform: "none",
                    }}
                    variant="contained"
                    size="large"
                    textTransform="none"
                  >
                     <img
                                      alt ="arrow"
                                      src={jsonimage}
                                      style={{
                                        marginTop: 0,
                                        marginBottom: 1,
                                        marginRight: 6,
                                        width:24,
                                        height:24
                                      }}
                                      />
                    <span style={{marginLeft:0,marginRight:3}}>Download JSON FILE</span>
                   
                  </Link>
                </div>)}
                {/**
                {timeseries?(null):( 
                <div
                  
                  style={{
                    //display: "flex",
                    //flexDirection: "row",
                    //alignItems: "center",
                    float: "right",
                    ml: 100,
                    cursor: "pointer",
                    marginTop:2
                  }}
                >
                  <Link
                    sx={{
                      fontSize: "13px",
                      fontStyle: "bold",
                      float: "right",
                      ml: 0,
                      mr: 0.5,
                      // marginTop: 0,
                      // marginBottom: 1,
                      textTransform: "none",
                    }}
                    variant="contained"
                    size="large"
                    textTransform="none"
                    href="https://drive.google.com/file/d/13VbUSDo0DWzglpqvDujzE_LFLvaMzTWA/view?usp=drive_link"
                    target="_blank"
                  >

                          
                     <img
                                      alt ="arrow"
                                      src={Gui}
                                      style={{
                                        marginTop: 0,
                                        marginBottom: 1,
                                        marginRight: 2,
                                        width:30,
                                        height:24
                                      }}
                                      />
                    <span style={{marginLeft:2,marginRight:10}}>Download Predictable GUI</span>
                   
                  </Link>
                </div>)}
                   */}
              </div>
                
              ) : (
                <div>
               
                <div
                  onClick={() => handleClickOpen()}
                  style={{
                    //display: "flex",
                    //flexDirection: "row",
                    //alignItems: "center",
                    float: "right",
                    ml: 100,
                    cursor: "pointer",
                  }}
                >
                   
                  {/*<DownloadIcon
                    sx={{
                      fontSize: "medium",
                      marginTop: 1,
                      mb: 1,
                      mr: 0.5,
                      color: Projectcolors.primary,
                    }}
                  /> */}
                  <Link
                    sx={{
                      fontSize: "13px",
                      fontStyle: "bold",
                      float: "right",
                      ml: 0,
                      mr: 0.5,
                      // marginTop: 0,
                      // marginBottom: 1,
                      textTransform: "none",
                    }}
                    variant="contained"
                    size="large"
                    textTransform="none"
                  >
                    <img
                                      alt ="arrow"
                                      src={Excel}
                                      style={{
                                        marginTop: 0,
                                        marginBottom: 1,
                                        marginRight: 1,
                                        width:30,
                                        height:30
                                      }}
                                    />
                    <span style={{marginLeft:3}}> Download Project Report</span>
                  </Link>
                </div>
                {timeseries?(null):(
                <div
                onClick={() => handleClickOpen()}
                style={{
                  //display: "flex",
                  //flexDirection: "row",
                  //alignItems: "center",
                  float: "right",
                  ml: 100,
                  cursor: "pointer",
                  marginTop:2
                }}
              >
               
                <Link
                  sx={{
                    fontSize: "13px",
                    fontStyle: "bold",
                    float: "right",
                    ml: 0,
                    mr: 0.5,
                    // marginTop: 0,
                    // marginBottom: 1,
                    textTransform: "none",
                  }}
                  variant="contained"
                  size="large"
                  textTransform="none"
                >
                  <img
                                    alt ="arrow"
                                    src={Hysys}
                                    style={{
                                      marginTop: 0,
                                      marginBottom: 1,
                                      marginRight: 7,
                                      width:25,
                                      height:25
                                    }}
                                    />
                  <span style={{marginLeft:0,marginRight:3}}>Download HYSYS Extension</span>
                </Link>
              </div>
              )}
                </div>

              )}

              


              <Box
                m="auto"
                sx={{
                  width: 1050,
                  // height: 390,
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "35px",

                  //marginLeft: 2,
                }}
              >
                {/* LEFT DIV */}
                <Box
                  sx={{
                    width: 230,
                    height: 310,
                    marginTop: "0px",
                    marginBottom: "30px",
                    mr: 2,
                    // marginRight: "10px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "white",
                      position: "relative",
                      top: 8,
                      left: 15,
                      width: 155,
                    }}
                  >
                    <Typography sx={{ fontSize: "13PX", fontWeight: "bold" }}>
                      &nbsp;&nbsp;Independent Variables
                    </Typography>
                  </div>
                  <Box
                    sx={{
                      width: 230,
                      height: 310,
                      marginTop: "0px",
                      border: 1,
                      borderColor: Projectcolors.primary,
                      padding: 1,
                      overflowY: "scroll",
                      marginLeft: "1px",
                    }}
                  >
                    <div>
                      {getvalues.length

                        ? 
                        fp.length>0?
                        fp[0].map((item, i) => {
                          return (
                            <div>
                              {timeseries ? (
                                <FormControl
                                  sx={{
                                    float: "left",
                                    marginRight: 2,
                                    marginTop: 2,
                                    marginLeft: 2,
                                    width: 160,
                                  }}
                                  size="small"
                                >
                                  <Typography sx={{ fontSize: 11 }}>
                                    {independent[i]}({unitsin[i]})
                                  </Typography>
                                  <TextField
                                    id="datetime-local"
                                    placeholder=""
                                    type="datetime-local"
                                    defaultValue={getvalues[i]}
                                    onChange={(e) => {
                                      handledateChange(e);
                                    }}
                                    sx={{ width: "170px" }}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    inputProps={{
                                      fontSize: "11px",
                                      height: "37px",
                                    }}
                                  />
                                  <img
                                    alt ="arrow"
                                    src={Arrow}
                                    style={{
                                      width: "160px",
                                      height: "28px",
                                      marginTop: 1,
                                      // marginRight:5
                                      // backgroundColor:"red"
                                    }}
                                  />
                                </FormControl>
                              ) : (
                                <FormControl
                                  sx={{
                                    float: "right",
                                    marginRight: 2,
                                    marginTop: 2,
                                    marginLeft: 1,
                                    width: 160,
                                  }}
                                  size="small"
                                >
                                  <Typography sx={{ fontSize: 11 }}>
                                    {item[1]}
                                  </Typography>

                                  <OutlinedInput
                                    id="outlined-basic"
                                    variant="outlined"
                                    placeholder=""
                                    sx={{
                                      width: "135px",
                                      fontSize: "11px",
                                      height: "30px",
                                    }}
                                    onChange={(e) => {
                                      handleChange(e, i);
                                    }}
                                    defaultValue={getvalues[i]}
                                    size="small"
                                  />
                                  <img
                                  alt ="arrow"
                                    src={Arrow}
                                    style={{
                                      width: "150px",
                                      height: "28px",
                                      marginTop: 1,
                                      // marginRight:5
                                      // backgroundColor:"red"
                                    }}
                                  />
                                </FormControl>
                              )}
                            </div>
                          );
                        }):
                        independent.map((item, i) => {
                            return (
                              <div>
                                {timeseries ? (
                                  <FormControl
                                    sx={{
                                      float: "left",
                                      marginRight: 2,
                                      marginTop: 2,
                                      marginLeft: 2,
                                      width: 160,
                                    }}
                                    size="small"
                                  >
                                    <Typography sx={{ fontSize: 11 }}>
                                      {independent[i]}({unitsin[i]})
                                    </Typography>
                                    <TextField
                                      id="datetime-local"
                                      placeholder=""
                                      type="datetime-local"
                                      defaultValue={getvalues[i]}
                                      onChange={(e) => {
                                        handledateChange(e);
                                      }}
                                      sx={{ width: "170px" }}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      inputProps={{
                                        fontSize: "11px",
                                        height: "37px",
                                      }}
                                    />
                                    <img
                                      alt ="arrow"
                                      src={Arrow}
                                      style={{
                                        width: "160px",
                                        height: "28px",
                                        marginTop: 1,
                                        // marginRight:5
                                        // backgroundColor:"red"
                                      }}
                                    />
                                  </FormControl>
                                ) : (
                                  <FormControl
                                    sx={{
                                      float: "right",
                                      marginRight: 2,
                                      marginTop: 2,
                                      marginLeft: 1,
                                      width: 160,
                                    }}
                                    size="small"
                                  >
                                    <Typography sx={{ fontSize: 11 }}>
                                      {independent[i]}({unitsin[i]})
                                    </Typography>

                                    <OutlinedInput
                                      id="outlined-basic"
                                      variant="outlined"
                                      placeholder=""
                                      sx={{
                                        width: "135px",
                                        fontSize: "11px",
                                        height: "30px",
                                      }}
                                      onChange={(e) => {
                                        handleChange(e, i);
                                      }}
                                      defaultValue={getvalues[i]}
                                      size="small"
                                    />
                                    <img
                                    alt ="arrow"
                                      src={Arrow}
                                      style={{
                                        width: "150px",
                                        height: "28px",
                                        marginTop: 1,
                                        // marginRight:5
                                        // backgroundColor:"red"
                                      }}
                                    />
                                  </FormControl>
                                )}
                              </div>
                            );
                          })
                        : 
                        fp.length>0?
                        fp[0].map((item, i) => {
                          return (
                            <div>
                              {timeseries ? (
                                <FormControl
                                  sx={{
                                    float: "left",
                                    marginRight: 2,
                                    marginTop: 2,
                                    marginLeft: 2,
                                    width: 160,
                                  }}
                                  size="small"
                                >
                                  <Typography sx={{ fontSize: 11 }}>
                                    {item[1]}
                                  </Typography>
                                  <TextField
                                    id="datetime-local"
                                    placeholder=""
                                    type="datetime-local"
                                    //defaultValue={getvalues[i]}
                                    onChange={(e) => {
                                      handledateChange(e);
                                    }}
                                    sx={{ width: "170px" }}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    inputProps={{
                                      fontSize: "11px",
                                      height: "37px",
                                    }}
                                  />
                                  <img
                                  alt ="arrow"
                                    src={Arrow}
                                    style={{
                                      width: "160px",
                                      height: "28px",
                                      marginTop: 1,
                                      // marginRight:5
                                      // backgroundColor:"red"
                                    }}
                                  />
                                </FormControl>
                              ) : (
                                <FormControl
                                  sx={{
                                    float: "right",
                                    marginRight: 2,
                                    marginTop: 2,
                                    marginLeft: 1,
                                    width: 160,
                                  }}
                                  size="small"
                                >
                                  <Typography sx={{ fontSize: 11 }}>
                                    {item[1]}
                                  </Typography>

                                  <OutlinedInput
                                    id="outlined-basic"
                                    variant="outlined"
                                    placeholder=""
                                    sx={{
                                      width: "135px",
                                      fontSize: "11px",
                                      height: "30px",
                                    }}
                                    onChange={(e) => {
                                      handleChange(e, i);
                                    }}
                                    //defaultValue={getvalues[i]}
                                    size="small"
                                  />
                                  <img
                                  alt ="arrow"
                                    src={Arrow}
                                    style={{
                                      width: "150px",
                                      height: "28px",
                                      marginTop: 1,
                                      // marginRight:5
                                      // backgroundColor:"red"
                                    }}
                                  />
                                </FormControl>
                              )}
                            </div>
                          );
                        }):independent.map((item, i) => {
                          return (
                            <div>
                              {timeseries ? (
                                <FormControl
                                  sx={{
                                    float: "left",
                                    marginRight: 2,
                                    marginTop: 2,
                                    marginLeft: 2,
                                    width: 160,
                                  }}
                                  size="small"
                                >
                                  <Typography sx={{ fontSize: 11 }}>
                                    {independent[i]}({unitsin[i]})
                                  </Typography>
                                  <TextField
                                    id="datetime-local"
                                    placeholder=""
                                    type="datetime-local"
                                    //defaultValue={getvalues[i]}
                                    onChange={(e) => {
                                      handledateChange(e);
                                    }}
                                    sx={{ width: "170px" }}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    inputProps={{
                                      fontSize: "11px",
                                      height: "37px",
                                    }}
                                  />
                                  <img
                                  alt ="arrow"
                                    src={Arrow}
                                    style={{
                                      width: "160px",
                                      height: "28px",
                                      marginTop: 1,
                                      // marginRight:5
                                      // backgroundColor:"red"
                                    }}
                                  />
                                </FormControl>
                              ) : (
                                <FormControl
                                  sx={{
                                    float: "right",
                                    marginRight: 2,
                                    marginTop: 2,
                                    marginLeft: 1,
                                    width: 160,
                                  }}
                                  size="small"
                                >
                                  <Typography sx={{ fontSize: 11 }}>
                                    {independent[i]}({unitsin[i]})
                                  </Typography>

                                  <OutlinedInput
                                    id="outlined-basic"
                                    variant="outlined"
                                    placeholder=""
                                    sx={{
                                      width: "135px",
                                      fontSize: "11px",
                                      height: "30px",
                                    }}
                                    onChange={(e) => {
                                      handleChange(e, i);
                                    }}
                                    //defaultValue={getvalues[i]}
                                    size="small"
                                  />
                                  <img
                                  alt ="arrow"
                                    src={Arrow}
                                    style={{
                                      width: "150px",
                                      height: "28px",
                                      marginTop: 1,
                                      // marginRight:5
                                      // backgroundColor:"red"
                                    }}
                                  />
                                </FormControl>
                              )}
                            </div>
                          );
                        })
                        

                          
                          
                          }

                          
                    </div>
                  </Box>
                </Box>
                {/* CENTER DIV */}
                <Box sx={{ height: 390 }}>
                  <Box
                    sx={{
                      width: 355,
                      height: 310,
                      padding: 5,
                      boxShadow: 9,
                      border: 1,
                      borderWidth: "4px",
                      borderColor: Projectcolors.primary,
                      borderRadius: 3,
                      fontSize: 20,
                      textAlign: "center",
                      marginTop: 3,
                      marginRight: 1,
                    }}
                  >
                    <Typography sx={{ fontSize: "13px" }}>
                      SelexMB Trained Model
                    </Typography>

                    <img
                    alt ="model"
                      src={model}
                      style={{
                        marginTop: 40,
                        objectFit: "contain",
                        height: "130px",
                      }}
                    />
                  </Box>
                  <Box sx={{ height: "40px" }}>
                    <Button
                      onClick={() => checkinput()}
                      disabled={!props.editAccess}
                      mr={4}
                      sx={{
                        width: 140,
                        height: 35,
                        fontSize: "12px",
                        backgroundColor: Projectcolors.primary,
                        color: "#fff",
                        fontStyle: "bold",
                        marginBottom: 5,
                        float: "left",
                        ml: 1,
                        marginTop: 2,
                        textTransform: "none",
                      }}
                      variant="contained"
                      size="large"
                      textTransform="none"
                    >
                      Run SelexMB
                    </Button>
                    {/**
                    <Link
                    sx={{
                      fontSize: "13px",
                      fontStyle: "bold",
                      float: "right",
                      ml: 0,
                      mr: 0.5,
                      mt:4,
                      // marginTop: 0,
                      // marginBottom: 1,
                      textTransform: "none",
                    }}
                    variant="contained"
                    size="large"
                    textTransform="none"
                    href="/tcpserver"
                    target="_blank"
                  >

                          
                   
                    <span style={{marginLeft:2,marginRight:10}}>Real Time Prediction</span>
                   
                  </Link>
                   */}
                  </Box>
                </Box>
                {/* RIGHT DIV */}
                <Box
                  sx={{
                    width: "365px",
                    height: 310,
                    marginTop: "0px",
                    marginLeft: "10px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "white",
                      position: "relative",
                      top: 8,
                      left: 15,
                      width: 143,
                    }}
                  >
                    <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                      &nbsp;&nbsp;Dependent Variables
                    </Typography>
                  </div>
                  <Box
                    sx={{
                      width: "376px",
                      height: 310,
                      marginTop: "2px",
                      border: 1,
                      borderColor: Projectcolors.primary,
                      padding: 1,
                      overflowY: "scroll",
                      marginLeft: "1px",
                    }}
                  >
                    
                    {
                    fp.length>0?
                    fp[1].map((item, i) => {
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: 1,
                          }}
                        >
                          <Box sx={{ width: 260 }}>
                            <Typography
                              sx={{ fontSize: "11px", marginLeft: 1 }}
                            >
                              {item[1]} 
                            </Typography>
                            <Typography>
                              <img
                              alt ="arrow"
                                src={Arrow}
                                style={{
                                  width: "160px",
                                  height: "28px",
                                  marginTop: 0,
                                  marginLeft: "8px",
                                  // marginRight:5
                                  // backgroundColor:"red"
                                }}
                              />
                            </Typography>
                          </Box>
                          <Box>
                            <FormControl
                              sx={{
                                float: "left",
                                marginTop: 0,
                                marginLeft: 0,
                                marginRight: 3,
                              }}
                              variant="outlined"
                              size="small"
                            >
                              <OutlinedInput
                                sx={{
                                  width: "135px",
                                  fontSize: "11px",
                                  height: "30px",
                                  marginTop: 2,
                                }}
                                id="outlined-basic"
                                variant="outlined"
                                value={predicted ? predicted[i] : null}
                                placeholder=" "
                                size="small"
                              />
                            </FormControl>
                          </Box>
                        </Box>
                      );
                    }):
                    
                    
                    dependent.map((item, i) => {
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: 1,
                          }}
                        >
                          <Box sx={{ width: 260 }}>
                            <Typography
                              sx={{ fontSize: "11px", marginLeft: 1 }}
                            >
                              {item} ({unitsde[i]})
                            </Typography>
                            <Typography>
                              <img
                              alt ="arrow"
                                src={Arrow}
                                style={{
                                  width: "160px",
                                  height: "28px",
                                  marginTop: 0,
                                  marginLeft: "8px",
                                  // marginRight:5
                                  // backgroundColor:"red"
                                }}
                              />
                            </Typography>
                          </Box>
                          <Box>
                            <FormControl
                              sx={{
                                float: "left",
                                marginTop: 0,
                                marginLeft: 0,
                                marginRight: 3,
                              }}
                              variant="outlined"
                              size="small"
                            >
                              <OutlinedInput
                                sx={{
                                  width: "135px",
                                  fontSize: "11px",
                                  height: "30px",
                                  marginTop: 2,
                                }}
                                id="outlined-basic"
                                variant="outlined"
                                value={predicted ? predicted[i] : null}
                                placeholder=" "
                                size="small"
                              />
                            </FormControl>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              </Box>
              <Box>
                {modalTraining ? (
                  <Box
                    sx={{
                      width: "98%",
                      float: "left",
                      marginBottom: 0,
                      height: "10px",
                      marginTop: "0px",
                    }}
                  >
                    <LinearProgressWithLabel
                      value={progress}
                      sx={{ height: "6px" }}
                    />
                  </Box>
                ) : null}

                {trained ? (
                  <Box
                    sx={{
                      width: "98%",
                      float: "left",
                      marginBottom: 0,
                      height: "10px",
                      marginTop: "0px",
                    }}
                  >
                    <LinearProgressWithLabel
                      value={100}
                      sx={{ height: "6px" }}
                    />
                  </Box>
                ) : null}
              </Box>
            </Box>
          </Box>

          <Box
            component={Paper}
            elevation={10}
            sx={{ border: 0, width: 450, marginLeft: 15 }}
          >
            <div
              className="content"
              style={{ backgroundColor: "#e2e2e2", padding: 10 }}
            >
              <Typography sx={{ fontSize: 15 }}>Selected Snapshot</Typography>
            </div>

            <div style={{ padding: 5 }}>
              <div>
                <Box
                  component={Paper}
                  elevation={2}
                  sx={{
                    width: "340px",
                    height: "430px",
                    border: 2,
                    marginLeft: 1,
                    marginTop: 2,
                    overflowX: "scroll",
                    overflowY: "scroll",
                    padding: 2,
                  }}
                >
                  <Typography
                    sx={{ fontSize: 14, display: "inline", padding: 1 }}
                  >
                    Snapshot {selectedsnapshot}
                  </Typography>

                  <Divider />

                  <Typography sx={{ fontSize: 11, padding: 0.5 }}>
                    Total Variable : {snapdetails.length}
                  </Typography>
                  {snapdetails.length > 0 ? (
                    <div>
                      {typeof snapdetails[0][1] !== "string" ? (
                        <div>
                          <Typography sx={{ fontSize: 11, padding: 0.5 }}>
                            Train Data % : {snapdetails[0][9][0]}
                          </Typography>
                          <Typography sx={{ fontSize: 11, padding: 0.5 }}>
                            No of Layers : {snapdetails[0][9][1]}
                          </Typography>
                          <Typography sx={{ fontSize: 11, padding: 0.5 }}>
                            No of Epochs : {snapdetails[0][9][2]}
                          </Typography>
                          <Typography sx={{ fontSize: 11, padding: 0.5 }}>
                            Batch Size : {snapdetails[0][9][3]}
                          </Typography>
                        </div>
                      ) : null}
                    </div>
                  ) : null}

                  {snapdetails.map((item, i) => {
                    if (cat[i] === false) {
                      return (
                        <div>
                          <Typography
                            sx={{
                              fontSize: 11,
                              padding: 0.5,
                              marginTop: 1.2,
                            }}
                          >
                            Dependent variable : {item[0]}
                          </Typography>
                          <Typography sx={{ fontSize: 11, padding: 0.5 }}>
                            Machine Learning Method :{" "}
                            {typeof item[1] === "string" ? item[1] : "ANN"}
                          </Typography>

                          <Typography sx={{ fontSize: 11, padding: 0.5 }}>
                            R2(Training) Score : {item[5]}
                          </Typography>

                          <Typography sx={{ fontSize: 11, padding: 0.5 }}>
                            R2(Training) - RMSE : {item[6]}
                          </Typography>
                          {timeseries ? null : (
                            <Typography sx={{ fontSize: 11, padding: 0.5 }}>
                              R2(Testing) Score : {item[7]}
                            </Typography>
                          )}

                          {timeseries ? null : (
                            <Typography sx={{ fontSize: 11, padding: 0.5 }}>
                              R2(Testing) - RMSE : {item[8]}
                            </Typography>
                          )}

                          <Divider />
                        </div>
                      );
                    } else {
                      return (
                        <div>
                          <Typography
                            sx={{
                              fontSize: 11,
                              padding: 0.5,
                              marginTop: 1.2,
                            }}
                          >
                            Dependent variable : {item[0]}
                          </Typography>
                          <Typography sx={{ fontSize: 11, padding: 0.5 }}>
                            Machine Learning Method :{" "}
                            {typeof item[1] === "string" ? item[1] : "ANN"}
                          </Typography>

                          <Typography sx={{ fontSize: 11, padding: 0.5 }}>
                            Precision : {item[6][0]}
                          </Typography>

                          <Typography sx={{ fontSize: 11, padding: 0.5 }}>
                            Recall : {item[6][1]}
                          </Typography>
                          <Typography sx={{ fontSize: 11, padding: 0.5 }}>
                            F1 Score : {item[7]}
                          </Typography>

                          <Typography sx={{ fontSize: 11, padding: 0.5 }}>
                            Accuracy : {item[8]}
                          </Typography>

                          <Divider />
                        </div>
                      );
                    }
                  })}
                </Box>
              </div>
            </div>
          </Box>
        </Box>
      </Box>

      {getLoader ? <GlobalLoader /> : null}
    </div>
  );
}

export default Deploy;
