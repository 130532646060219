import {
    Box,
    Button,
    CircularProgress,
    Snackbar,
    TextField,
    Typography,
  } from "@mui/material";
  import React from "react";
  import { useEffect, useState } from "react";

  import { useNavigate } from "react-router-dom";
  import { baseURL } from "../Config/config";
  
  import { Link } from "@mui/material";
  import Modal from "@mui/material/Modal";
  import { Paper } from "@mui/material";
  import CloseIcon from "@mui/icons-material/Close";
  import Projectcolors from "../Utils/Colors";
  
  import Selexlogo from "../images/SelexMB Final.png";
  import Selexrisk from "../images/SelexRiskFinal.png";

  import selexmathimg from "../images/SelexMath-2.png";
  import SubNavbar from "./CommonC/SubNavbar";
  import { Alert } from "@mui/material";
  import BgContainer2 from "./CommonC/BgContainer2";
  import Grow from '@mui/material/Grow';
  import './YourComponent.css';
  import SelexSolNavbar from "./CommonC/SelexSolNavbar";

  
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    height: 700,
    bgcolor: "white",
    border: "2px solid #000",
    p: 4,
    overflow: "scroll",
    display: "block",
  };
  const stylec = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    height: 450,
    bgcolor: "white",
    border: "2px solid #000",
    p: 4,
    display: "block",
  };



  
  const Select = ({handleNavigate}) => {
    const navigate = useNavigate();
    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [hovered, setHovered] = useState(false);
    const [hoveredselexmath, setHoveredSelex] = useState(false);
    const [hoveredselexrisk, setHoveredRisk] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [openc, setOpenc] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [state, setState] = React.useState({
      isOpen: false,
      vertical: "top",
      horizontal: "center",
    });
    const [checked, setChecked] = React.useState(false);
   
  
    const { vertical, horizontal, isOpen } = state;
  
    const handleOpenc = () => setOpenc(true);
    const handleClosec = () => setOpenc(false);
  
    const handleAuth = async () => {
      setLoading(true);
      const body = {
        username,
        password,
      };
  
      const options = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      };
      try {
        let result = await fetch(`${baseURL}/login`, options);
        result = await result.json();
        if (result.token) {
          handleNavigate(result.token)
          navigate("/home");
          setLoading(false);
        }
        setLoading(false);
        handleSnackbar(true);
      } catch (error) {
        setLoading(false);
        console.log("error", error);
      }
  
      return;
    };
  
    const handleSnackbar = (value) => {
      setState({ ...state, isOpen: value });
    };

    React.useEffect(() => {
      setChecked(true);
    }, [])
  
    return (
      <div
        style={{
          height: window.screen.height,
        }}
      >
        <BgContainer2>
        <SelexSolNavbar />

  
          <br />
          <div
            style={{
              backgroundColor: "rgba(255,255,255)",
              //flexDirection: "row",
              borderRadius: "10px",
              //border: "1px solid #0078C9",
              alignItems: "center",
              marginTop: "9%",
              marginLeft: "35%",
              height:'250px',
              width:'530px',
              padding:'20px'
            }}
          >
            <Typography sx={{fontSize:16,color:"#0078C9",fontWeight:"bold"}}>Select a Solution : </Typography>
          <div
            style={{
              backgroundColor: "rgba(255,255,255)",
              display: "flex",
              flex: 1,
              flexDirection: "row",
              borderRadius: "10px",
              //border: "1px solid #0078C9",
              alignItems: "center",
              //marginTop: "9%",
              //marginLeft: "35%",
              height:'130px',
              width:'500px',
              padding:'10px'
            }}
          >
            <Grow in={checked} timeout={2000}>
            <div
              style={{
                backgroundColor: "rgba(255,255,255)",
                display: "flex",
                padding: "2px 70px 2px 70px",
                marginTop: "48px",
                flexDirection: "column",
                borderRadius: "10px",
                //border: "1px solid #0078C9",
                marginBottom: "10px",
                marginLeft:"1%",
                position: "relative",
                height:'145px',
                width:'360px'

              }}
            >
              <div style={{ position: "absolute", left: "2px",alignItems:"center" }} className="zoom">
              <Link
                sx={{
                  fontSize: "10px",
                  marginTop: "8px",
                  color: Projectcolors.primary,
                  cursor: "pointer",
                }}
                onClick={() => navigate("/home")}
              >
                <img
                  style={{
                    height: "70px",
                    width: "145px",
                    marginLeft:"2%",
                    marginTop: "16%",
                    objectFit: "contain",
                  }}
                  src={Selexlogo}
                  onMouseEnter={() => setHovered(true)}
                  onMouseLeave={() => setHovered(false)}
                />
                </Link>

              </div>
  
             
  
              
              
  
             
            </div>
            </Grow>

            <Grow in={checked} timeout={2000}>
            <div
              style={{
                backgroundColor: "rgba(255,255,255)",
                display: "flex",
                padding: "2px 70px 2px 70px",
                marginTop: "48px",
                flexDirection: "column",
                borderRadius: "10px",
                //border: "1px solid #0078C9",
                marginBottom: "10px",
                marginLeft:"1%",
                position: "relative",
                height:'145px',
                width:'360px'

              }}
            >
              <div style={{ position: "absolute", left: "2px",alignItems:"center" }} className="zoom">
              <Link
                sx={{
                  fontSize: "10px",
                  marginTop: "8px",
                  color: Projectcolors.primary,
                  cursor: "pointer",
                }}
                onClick={() => navigate("/selectselexmath")}
              >
                <img
                  style={{
                    height: "70px",
                    width: "145px",
                    marginLeft:"2%",
                    marginTop: "16%",
                    objectFit: "contain",
                  }}
                  src={selexmathimg}
                  onMouseEnter={() => setHoveredSelex(true)}
                  onMouseLeave={() => setHoveredSelex(false)}
                />
                </Link>

              </div>
  
             
  
              
              
  
             
            </div>
            </Grow>

            <Grow in={checked} timeout={2000}>
            <div
              style={{
                backgroundColor: "rgba(255,255,255)",
                display: "flex",
                padding: "2px 70px 2px 70px",
                marginTop: "48px",
                flexDirection: "column",
                borderRadius: "10px",
                //border: "1px solid #0078C9",
                marginBottom: "10px",
                marginLeft:"1%",
                position: "relative",
                height:'145px',
                width:'360px'

              }}
            >
              <div style={{ position: "absolute", left: "2px",alignItems:"center" }} className="zoom">
              <Link
                sx={{
                  fontSize: "10px",
                  marginTop: "8px",
                  color: Projectcolors.primary,
                  cursor: "pointer",
                }}
                onClick={() => navigate("/selexrisk")}
              >
                <img
                  style={{
                    height: "95px",
                    width: "148px",
                    marginLeft:"2%",
                    marginTop: "10%",
                    objectFit: "contain",
                  }}
                  src={Selexrisk}
                  onMouseEnter={() => setHoveredRisk(true)}
                  onMouseLeave={() => setHoveredRisk(false)}
                />
                </Link>

              </div>
  
             
  
              
              
  
             
            </div>
            </Grow>
           

            
             
          </div>
          <div
              style={{
               
                display: "flex",
                
                flexDirection: "row",
                marginTop:"15px"
                

              }}
            >
              <div>
                <Typography sx={{fontSize:16,color:"#0078C9",fontWeight:"bold",marginLeft:7.5,marginTop:1}}>SelexMB</Typography>
                </div>
                <div>
                <Typography sx={{fontSize:16,color:"#0078C9",fontWeight:"bold",marginLeft:9.5,marginTop:1}}>SelexMath</Typography>
                </div>
                <div>
                <Typography sx={{fontSize:16,color:"#0078C9",fontWeight:"bold",marginLeft:10.5,marginTop:1}}>SelexRISK</Typography>
                </div>
              </div>
          </div>

          
  

          {hovered && (
        <div
          style={{
            position: "absolute",
            top: "10%", // Adjust this based on your layout
            left: "2%", // Extreme left of the screen
            backgroundColor: "white",
            border: "1px solid #ccc",
            padding: "10px",
            borderRadius: "5px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            zIndex: 1000, // Ensure it appears on top
            width:300,
            height:650
          }}
        >
          <Typography sx={{ fontSize: 16, color: Projectcolors.primary }}>
          SelexMB™ </Typography>
          <Typography sx={{ fontSize: 14, color: "#333" }}>
          Revolutionizing AI-Driven Operations in the Energy Sector
SelexMB™ is a cutting-edge software solution by Emad Gebesy that empowers energy companies to harness the power of artificial intelligence and machine learning for enhanced decision-making and operational efficiency. Designed as a SaaS application, SelexMB™ features a robust library of machine learning (ML) and artificial neural network (ANN) tools, as well as hybrid modeling capabilities that seamlessly integrate first principles with AI-driven insights.
Fully compatible with industry-leading software such as Aspen HYSYS and AspenOnline, SelexMB™ enables autonomous AI-based operations, driving transformative outcomes in sustainability, efficiency, and performance. Whether you're optimizing processes, reducing emissions, or advancing the hydrogen economy, SelexMB™ offers unparalleled support across the appraise and select phases of sustainability projects.
Experience the future of energy solutions with SelexMB™—your partner in achieving smarter, greener, and more efficient operations.
          </Typography>
        </div>
      )}

{hoveredselexmath && (
        <div
          style={{
            position: "absolute",
            top: "10%", // Adjust this based on your layout
            left: "2%", // Extreme left of the screen
            backgroundColor: "white",
            border: "1px solid #ccc",
            padding: "10px",
            borderRadius: "5px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            zIndex: 1000, // Ensure it appears on top
            width:300,
            height:700
          }}
        >
          <Typography sx={{ fontSize: 16, color: Projectcolors.primary }}>
          SelexMath™ 
          </Typography>
          <Typography sx={{ fontSize: 14, color: "#333" }}>
          
          Advanced Mathematical Modeling for Next-Generation Energy Solutions
SelexMath™ by Emad Gebesy is a revolutionary software tool designed to simplify and accelerate mathematical modeling for the energy sector. With its powerful capabilities, SelexMath™ bridges the gap between complex engineering challenges and actionable insights, enabling organizations to innovate and optimize with confidence.
The software specializes in creating advanced mathematical models, including Lean Model Dynamics (LMD) for both discrete and stochastic systems. These models are engineered to capture the essential behavior of complex systems while significantly reducing computational demands, making them ideal for real-time analysis and decision-making.
SelexMath™ empowers R&D, Engineers and Operations teams with tools for developing precise, efficient solutions for process monitoring and optimization. By delivering robust support across the appraise and select phases of sustainability projects, SelexMath™ ensures that innovation is not only accessible but also cost-effective.
Unlock the potential of data-driven and mathematically optimized operations with SelexMath™—your trusted partner in transforming the energy landscape.

          </Typography>
        </div>
      )}
  
  {hoveredselexrisk && (
        <div
          style={{
            position: "absolute",
            top: "10%", // Adjust this based on your layout
            left: "2%", // Extreme left of the screen
            backgroundColor: "white",
            border: "1px solid #ccc",
            padding: "10px",
            borderRadius: "5px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            zIndex: 1000, // Ensure it appears on top
            width:330,
            height:750
          }}
        >
          <Typography sx={{ fontSize: 16, color: Projectcolors.primary }}>
          SelexRISK™
          </Typography>
          <Typography sx={{ fontSize: 14, color: "#333" }}>
          Comprehensive Risk Management and Process Safety Analysis
SelexRISK™ is a state-of-the-art software tool from Emad Gebesy, purpose-built to advance risk management and process safety in the energy sector. By integrating sophisticated methodologies, SelexRISK™ streamlines the identification, analysis, and mitigation of potential hazards across complex operations.
SelexRISK™ offers a robust framework for Process Hazard Analysis (PHA), encompassing Root Cause Analysis, Vulnerability Assessment, Safeguard Evaluation, Consequence Modeling, and Mitigation Planning. Leveraging advanced tools, including insights from SelexMB™, the software enables precise risk quantification through metrics such as Intermediate Event Likelihood (IEL) and Time at Risk, while accounting for critical factors like Ignition Probability and Occupancy Factors.
Equipped with a master database and an intuitive graphical user interface (GUI), SelexRISK™ simplifies project and node management, ensuring that every risk—from minor deviations to catastrophic events—is systematically addressed. Its unique capabilities include the development of tailored risk matrices, severity assessments, and post-mitigation evaluations, empowering organizations to make informed, proactive decisions.
Transform your approach to safety and risk management with SelexRISK™—your partner in fostering a safer, more resilient energy future.

          </Typography>
        </div>
      )}
          
        </BgContainer2>
      </div>
    );
  };
  
  export default Select;
  